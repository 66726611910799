<header id="header" class="header__root" [class.header_change]="header_variable">
    <nav class="navbar navbar-expand-lg align-items-center" >
        <div class="navbar-brand">
            <a href="/" *ngFor="let item of logo; let i=index">
                <img loading="lazy"  class="dark__logo" [hidden]="!header_variable" *ngIf="i===0 && item?.directus_files_id"
                    src="{{imgURL}}{{item?.directus_files_id}}" alt="{{allData.image_alt_text}}">
                <img loading="lazy"  class="light__logo" [hidden]="header_variable" *ngIf="i===1 && item?.directus_files_id"
                    src="{{imgURL}}{{item?.directus_files_id}}" alt="{{allData.image_alt_text}}" >
            </a>
        </div>
        <div class="web-nav" id="navbarText" >
            <ul class="navbar-nav mb-2 mb-lg-0">
                <li class="nav-item dropdown" *ngFor="let nav of navigation; let i=index" [ngClass]="(nav.slug===urlActive)?'active':''">
                    <a class="nav-link dropbtn" routerLink="{{nav.slug}}" (click)="scrollTop()">
                        {{nav?.name}}
                    </a>
                    <!-- change {{nav.class}} to "dropdown-content" in below class -->
                    <div class="dropdown-content" *ngIf="nav.sub_navigation_ref.length>=1">
                            <a href="{{subNav.slug}}" *ngFor="let subNav of nav?.sub_navigation_ref">
                                <div class="sec-points">
                                    <img loading="lazy"  *ngIf="subNav.image" src="{{imgURL}}{{subNav?.image}}" alt="{{subNav?.image_alt_text}}">
                                    <div class="wrap">
                                        <h4 *ngIf="subNav?.name">{{subNav.name}}</h4>
                                        <p *ngIf="subNav.short_description">{{subNav.short_description}}</p>
                                    </div>
                                </div>
                            </a>
                        <div class="learn__more" *ngIf="nav?.extra_link">
                            <a *ngIf="nav.extra_link_text" class="links" href="{{nav.extra_link}}">{{nav.extra_link_text}}
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
            <button class="btn btns" (click)="goToPage('contact')" *ngIf="buttons">
                {{buttons}}
            </button>
        </div>
    </nav>
    <!-- mobile header starts -->
        <a class="btn drawwer--btn" (click)="openNav()" href="javascript:;" role="button">
            <img loading="lazy" onerror="this.style.display='none'" [hidden]="header_variable" src="../../../assets/img/burger-icon.svg" alt="icon">
            <img loading="lazy" onerror="this.style.display='none'" [hidden]="!header_variable" src="../../../assets/img/black-burger-icon.svg" alt="icon">
        </a>

      <div id="mySidenav" class="sidenav">
        <div class="header">
            <a href="/" *ngIf="logo?.[0]?.directus_files_id">
                <img class="drawer__img" loading="lazy"  src="{{imgURL}}{{logo?.[0]?.directus_files_id}}" alt="{{allData?.image_alt_text}}">
            </a>
            <button class="closebtn" (click)="closeNavnew()">&times;</button>
        </div>
        <div class="offcanvas-body">
            <ul class="list-group list-group-flush ">
                <ng-container *ngFor="let nav of navigation; let i=index">
                    <li class="list-group-item toggle--root" [class.underline_none]="navigation.length==i+1?true:false">
                        <div class="toggle--btn">
                            <a *ngIf="nav.name" href="{{nav.slug}}" class="toggle--btn--text">
                                {{nav.name}}
                            </a>      
                            <ng-container *ngIf="i===0 || i===1">
                                <label class="menu-open flex-grow-1"  for="menu-toggle" (click)="innerCard(i)">
                                    <div class="float-right">
                                        <img loading="lazy" onerror="this.style.display='none'" alt="right-arrow" src="../../../assets/img/right-arrow.svg"
                                        alt="logo">
                                    </div>
                                </label>
                            </ng-container>                              
                        </div>
                        <input id="menu-toggle" type="checkbox">
                        <ul class="list">
                            <div class="header">
                                <img loading="lazy" *ngIf="logo?.[0]?.directus_files_id" src="{{imgURL}}{{logo?.[0]?.directus_files_id}}" alt="{{allData.image_alt_text}}" class="drawer__img">
                                <button class="closebtn" (click)="closeNav()">&times;</button>
                            </div>
                            <label id="close" class="menu-open back--btn--label" for="menu-toggle" > <img
                                    class="back--arrow" onerror="this.style.display='none'" src="../../../assets/img/left-arrow.svg"
                                    alt="logo">Back</label>
                            <ng-container *ngFor="let subNav of navigation[navIndex]?.sub_navigation_ref">
                                <li class="toggle--root"><a class="link" href="{{subNav.slug}}">
                                        <div class="d-flex">
                                            <img loading="lazy" class="subNavImg"  *ngIf="subNav.image" src="{{imgURL}}{{subNav?.image}}"
                                            alt="{{subNav?.image_alt_text}}">
                                            <p class="sub--page--link" *ngIf="subNav.name">{{subNav.name}}</p>
                                        </div>
                                    </a>
                                </li>
                            </ng-container>
                            <a *ngIf="navIndex===0 || navIndex === 1"
                                href="{{navigation[navIndex]?.extra_link}}" class="link--custom--style">
                                <h6 class="pages--link--text" *ngIf="navigation[navIndex].extra_link_text">{{navigation[navIndex].extra_link_text}}<img
                                        class="arrow--btn" onerror="this.style.display='none'" src="../../../assets/img/right-arrow.svg" alt="logo">
                                </h6>
                            </a>
                        </ul>
                    </li>
                </ng-container>
                <button class="btn mob--btns" (click)="goToPage(allData?.button_link)" *ngIf="allData?.button_name">
                    {{allData?.button_name}}
                </button>
            </ul>
        </div>
      </div>
</header>