import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
declare var $:any;

@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.scss']
})
export class HeaderTwoComponent implements OnInit {
  datas:any;
  buttons:any;
  allData:any;
  logo:any;
  navigation : any;
  navIndex:any;
  urlActive:any;

  constructor(private sdata:DataService , private router: Router, private transferState: TransferStateService,
    @Inject(DOCUMENT) private document: Document) { }

  
  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
    window.scroll(0,0);
  }
  
  imgURL= `${environment.apiURL}/assets/`;

  innerCard(i:number){
    this.navIndex = i ;
  }
    

  ngOnInit(){
    let headerTwoData = this.transferState.useScullyTransferState('headerTwoTS', this.sdata.getHeader());
    headerTwoData.subscribe((result)=>{
      this.datas = result;
      this.allData = this.datas?.data?.[0];
      this.buttons = this.allData?.button_name;
      this.navigation = this.allData?.navigations;
      this.logo = this.allData?.images;
    });

    this.urlActive = this.router?.url?.split('?')[0];
    }

  header_variable = false
  @HostListener("document:scroll")
  scrollfunction(){
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      this.header_variable = true;
    } else {
      this.header_variable = false;
    }
  }

  // function to go bcak and close mobile nav
  closeNav(){
    $('#close').click();
  }
  scrollTop(){
    window.scroll(0,0);
  }

   /* Open the sidenav */
  openNav() {
    $('#mySidenav').addClass("w-100");
    if($('#mySidenav').hasClass("sidenav w-100")){
      this.document.body.classList.add('body-overflow');
    }
  }
  opensubNav() {
    $('.list').addClass("w-100");
  }

  /* Close/hide the sidenav */
  closeNavnew() {
    $('#mySidenav').removeClass("w-100");
    if($('#mySidenav').hasClass("sidenav")){
      this.document.body.classList.remove('body-overflow');
    }
  }

}
