<app-header-two></app-header-two>


<!-- Hero-Section-careers starts -->
<div class="hero-careers-section"  *ngIf="banner" >
    <div class="container" *ngFor="let item of banner">
        <h1 *ngIf="item.heading">{{item.heading}}</h1>
        <div class="justify--center" *ngIf="item.description">
            <p>{{item.description}}</p>
        </div>
    </div>
</div>

<div class="container">
    <button type="button" class="floating_btn" id="floating_btn" (click)="scrollToCareer('#career')">
    Join The Team
    </button>
</div>

<!--  values section starts -->
<section class="pb-lg-5" *ngIf="values">
    <div class="values-section">
        <div class="container">
            <div class="row" *ngFor="let item of values">
                <div class="col-lg-6 col-12">
                    <div class="content">
                        <h2 *ngIf="item.heading">{{item.heading}}</h2>
                        
                            <img loading="lazy" onerror="this.style.display='none'" alt="border" class="divider" src='../../../assets/img/border.svg'>
                        
                        <p *ngIf="item.description">{{item.description}}</p>
                        <img loading="lazy" *ngIf="item.image" src="{{imgURL}}{{item.image?.id}}" alt="{{item?.image_alt_text}}" class="mobile-none">
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="values-cards">
                        <div class="accordion" id="accordionExample"
                            *ngFor="let subitem of item?.subcontent_sections; let i=index">
                            <div class="accordion-item" *ngIf="i===0">
                                <h2 class="accordion-header" id="headingOne" *ngIf="subitem.sub_head">
                                    <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseOne"
                                         aria-expanded="true" aria-controls="collapseOne" *ngIf="subitem.sub_head">
                                        {{subitem.sub_head}}
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body" *ngIf="subitem.sub_head_description">
                                        <p>{{subitem.sub_head_description}}</p>
                                        <ul>
                                            <li *ngFor="let feature of subitem?.sub_subcontent_sec_part"> <span class="list__icon"></span>{{feature.name}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item" *ngIf="i===1">
                                <h2 class="accordion-header" id="headingTwo" *ngIf="subitem.sub_head">
                                    <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo"
                                     aria-expanded="false" aria-controls="collapseTwo" *ngIf="subitem.sub_head">
                                        {{subitem.sub_head}}
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body" *ngIf="subitem.sub_head_description">
                                        <p>{{subitem.sub_head_description}}</p>
                                        <ul>
                                            <li *ngFor="let feature of subitem?.sub_subcontent_sec_part"><span class="list__icon"></span>{{feature.name}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item" *ngIf="i===2">
                                <h2 class="accordion-header" id="headingThree" *ngIf="subitem.sub_head">
                                    <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree" *ngIf="subitem.sub_head">
                                        {{subitem.sub_head}}
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body" *ngIf="subitem.sub_head_description">
                                        <p>{{subitem.sub_head_description}}</p>
                                        <ul>
                                            <li *ngFor="let feature of subitem?.sub_subcontent_sec_part"> <span class="list__icon"></span>{{feature.name}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item" *ngIf="i===3">
                                <h2 class="accordion-header" id="headingfour" *ngIf="subitem.sub_head">
                                    <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false"
                                        aria-controls="collapsefour" *ngIf="subitem.sub_head">
                                        {{subitem.sub_head}}
                                    </button>
                                </h2>
                                <div id="collapsefour" class="accordion-collapse collapse" aria-labelledby="headingfour"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body" *ngIf="subitem.sub_head_description">
                                        <p>{{subitem.sub_head_description}}</p>
                                        <ul>
                                            <li *ngFor="let feature of subitem?.sub_subcontent_sec_part"> <span class="list__icon"></span>{{feature.name}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item" *ngIf="i===04">
                                <h2 class="accordion-header" id="headingfive" *ngIf="subitem.sub_head">
                                    <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapsefive"aria-expanded="false"
                                        aria-controls="collapsefive" *ngIf="subitem.sub_head">
                                        {{subitem.sub_head}}
                                    </button>
                                </h2>
                                <div id="collapsefive" class="accordion-collapse collapse" aria-labelledby="headingfive"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body" *ngIf="subitem.sub_head_description">
                                        <p>{{subitem.sub_head_description}}</p>
                                        <ul>
                                            <li *ngFor="let feature of subitem?.sub_subcontent_sec_part"> <span class="list__icon"></span>{{feature.name}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- BDC Culture starts -->

<section class="section-bg-culture" *ngIf="culture">
    <div class="container">
        <div class="culture">
            <div class="row" *ngFor="let item of culture">
                <div class="col-lg-3 col-12">
                    <h2  *ngIf="item.heading">{{item.heading}}</h2>
                    <div>
                        <img loading="lazy" onerror="this.style.display='none'" class="divider" alt="border" src='../../../assets/img/border.svg'>
                    </div>
                </div>
                <div class="col-lg-9 col-12">
                    <p *ngIf="item.description">{{item.description}}</p>
                    <p *ngIf="item.additional_line">{{item.additional_line}}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Info card -->
<section class="info-section" *ngIf="Statistics">
    <div class="container">
        <div class="info-card">
            <div class="row">
                <div class="col-lg-3 col-6 " *ngFor="let item of Statistics; let i=index">
                    <h3 class="quantity" *ngIf="item.extra_link_text">{{item.extra_link_text}}</h3>
                    <h5 class="headline" *ngIf="item.sub_head">{{item.sub_head}}</h5>
                    <p class="text" *ngIf="item.sub_head_description">{{item.sub_head_description}}</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- common section starts -->

<!-- Hero-section-common content starts  -->
<!-- first common section -->
<div class="hero-common-content pt-0" *ngIf="whereWe">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-12 mobile-none" *ngFor="let item of whereWe">
                <img loading="lazy"  *ngIf="item.image" src="{{imgURL}}{{item.image?.id}}" alt="{{item?.image_alt_text}}">
            </div>
            <div class="col-lg-6 col-12">
                <div class="sub-content right" *ngFor="let item of whereWe">
                    <h2 *ngIf="item.heading">{{item.heading}}</h2>
                    <div>
                        <img loading="lazy" alt="border" onerror="this.style.display='none'" class="divider" src='../../../assets/img/border.svg'>
                    </div>
                    <p *ngIf="item.description">{{item.description}}</p>
                    <div class="list"  >
                        <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex mb-23 pr-125"><div><span class="list__icon"></span></div><div *ngIf="subitem.sub_head"><span class="list__item">{{subitem.sub_head}}</span></div></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12 web-none text-center" *ngFor="let item of whereWe">
                <img loading="lazy" *ngIf="item.image" src="{{imgURL}}{{item.image?.id}}" alt="{{item?.image_alt_text}}">
            </div>
        </div>
    </div>
</div>
<!-- second common part -->
<div class="hero-common-content" *ngIf="ourApproach">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-12">
                <div class="sub-content left" *ngFor="let item of ourApproach">
                    <h2 *ngIf="item.heading">{{item.heading}}</h2>
                    <div>
                        <img loading="lazy" alt="border" onerror="this.style.display='none'" class="divider" src='../../../assets/img/border.svg'>
                    </div>

                    <p *ngIf="item.description">{{item.description}}</p>
                    
                    <div class="list"  >
                        <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex mb-23 pr-155">
                            <div>
                                <span class="list__icon"></span>
                            </div>
                            <div>
                                <span class="list__item" *ngIf="subitem.sub_head">{{subitem.sub_head}}</span>
                                <h6 class="mobile-none sub_text" *ngIf="subitem.sub_head_description">{{subitem.sub_head_description}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12 mobile-none" *ngFor="let item of ourApproach">
                <img loading="lazy" *ngIf="item.image" src="{{imgURL}}{{item?.image?.id}}" alt="{{item?.image_alt_text}}">
            </div>
            <div class="col-lg-6 col-12 web-none text-center" *ngFor="let item of ourApproach">
                <img loading="lazy" *ngIf="item.image" src="{{imgURL}}{{item?.image?.id}}" alt="{{item?.image_alt_text}}">
            </div>
        </div>
    </div>
</div>
<!-- third common part -->
<div class="hero-common-content pb-lg-5 mb-lg-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-12 mobile-none" *ngFor="let item of diversity">
                <img loading="lazy" *ngIf="item.image" src="{{imgURL}}{{item?.image?.id}}" alt="{{item?.image_alt_text}}">
            </div>
            <div class="col-lg-6 col-12">
                <div class="sub-content right" *ngFor="let item of diversity">
                    <h2 *ngIf="item.heading">{{item.heading}}</h2>
                    <div>
                        <img loading="lazy" alt="border" class="divider" onerror="this.style.display='none'" src='../../../assets/img/border.svg'>
                    </div>
                    <p *ngIf="item.description">{{item.description}}</p>
                    <div class="list"  >
                        <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex mb-23 pr-125"><div><span class="list__icon"></span></div><div *ngIf="subitem.sub_head"><span class="list__item">{{subitem.sub_head}}</span></div></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12 web-none text-center" *ngFor="let item of diversity">
                <img loading="lazy" *ngIf="item.image" src="{{imgURL}}{{item?.image?.id}}" alt="{{item.image_alt_text}}">
            </div>
        </div>
    </div>
</div>
<!-- Section 2 starts here -->
<div id="whyLast"></div>
<app-why-brooklyn></app-why-brooklyn>

<div class="section2" id="career">
    <div class="container" *ngFor="let item of BDC">
        <div class="sec--2" *ngFor="let item of connect">
            <h2 *ngIf="item.heading">{{item.heading}}</h2>
            <div class="row  mb-4">
                <div class="col-xl-4 col-lg-6 col-sm-6 col-12 mt-lg-4 mt-0" *ngFor="let subItem of item?.subcontent_sections">
                    <div class="{{subItem.class}}">
                        <p class="team" *ngIf="subItem.sub_head">{{subItem.sub_head}}</p>
                        <h4 class="post" *ngIf="subItem.sub_head_description">{{subItem.sub_head_description}}</h4>
                        <div class="d-flex justify-content-between apply">
                            <p class="place">
                                <ng-container *ngIf="subItem?.location">
                                    <img loading="lazy" onerror="this.style.display='none'" src="../../../assets/img/career/location-icon.svg" alt="location" class="location">
                                    <p class="remote" *ngIf="subItem?.location">{{subItem.location}}</p>
                                </ng-container>
                            </p>
                            <div class="learn__more">
                                <a class="link" *ngIf="subItem.extra_link_text" href="{{subItem.extra_link}}">{{subItem.extra_link_text}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="register-content" *ngFor="let item of connect">
            <p *ngIf="item.description">{{item.description}}</p>
            <a *ngIf="item.button_link" href="{{item.button_link}}">
                <button class="btn btns" *ngIf="item?.button_label">{{item.button_label}}</button>
            </a>
        </div>
    </div>
</div>

<!-- Section 2 ends -->