<app-header-two></app-header-two>

<div class="general-section">
     <div class="container">
          <ng-container *ngFor="let item of banner">
               <h1>{{item?.heading}}</h1>
          </ng-container>
          <ng-container *ngFor="let item of headOne">
               <h2 class="mt1">{{item.heading}}</h2>
               <ng-container *ngFor="let subitem of allDataContent?.[0]?.content_sections; let i=index">
                    <div style="text-align: justify;" *ngIf="i===1" [innerHtml]= "subitem?.content_text"></div>
               </ng-container>
          </ng-container>
          <ng-container *ngFor="let item of headTwo">
               <h2 class="mt-3">{{item?.heading}}</h2>
               <ng-container *ngFor="let subitem of allDataContent?.[0]?.content_sections; let i=index">
                    <div style="text-align: justify;" *ngIf="i===2" [innerHtml]= "subitem?.content_text"></div>
               </ng-container>
          </ng-container>
     </div>
</div>