import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
declare function loadMeta(titVal:any,metaTitVal:any,metaDesVal:any ):any;
declare function pageMetaTypeURL(url: any, type: any): any;

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit {

  imgURL = `${environment?.apiURL}/assets/`
  allData:any;
  ContentSec:any;
  banner:any;
  related: any;
  relatedShow: any;
  dropDownCategory:any;
  dropDownTool:any;
  totalRec : any;
  page: number = 1;
  filter = {
    category:'',
    tools:'',
  }
  pageInfo: any;
  titleValue:any='';
  metaTitVal:any='';
  metaDesVal:any='';
  allDataBlog: any='';
  ContentSecStory: any;
  type: any='';
  pageUrl:any='';
  pageType:any='';

  setFilter(fType:string,fValue:string){
    if(fType==='category')
    this.filter.category=fValue;
    else{
      this.filter.tools=fValue;
    }
    this.showFilter();
  }

  clearCategory(){
    this.filter.category = ''
    this.showFilter();
  }

  clearTool(){
    this.filter.tools=''
    this.showFilter();
  }

  showFilter(){
    if(this.filter.category == '' && this.filter?.tools == ''){
      this.relatedShow = this.related;
      this.totalRec = this.relatedShow?.length;
    }
    this.relatedShow= this.related?.filter((e:any)=>{
      if(this.filter?.category !== '' && this.filter?.tools !== ''){
        if(e?.keywordsArray?.includes(this.filter?.category) && e?.keywordsArray?.includes(this.filter?.tools)){
          return true;
        }else
          return false;
      }else if(this.filter.category !== ''){
        if(e?.keywordsArray?.includes(this.filter?.category)){
          return true;
        }else
          return false;
      }else if(this.filter.tools !== ''){
        if(e?.keywordsArray?.includes(this.filter?.tools)){
          return true;
        }else
        return false;
      }else{
        return true;
      }
    });
    this.totalRec = this.relatedShow?.length;
  }

  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
  }

// recieve(){
//   this.data.getCaseStories(this.router.url).subscribe((result)=>{
//      this.allData = result;
//   })
// }


  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  ngOnInit() {
    let storyData = this.transferState.useScullyTransferState('storyDataTS', this.data.getPageData(this.router?.url?.split('?')[0]));
    storyData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections
        this.pageInfo = this.allData
        // // this.titleValue = this.pageInfo?.title
        this.metaTitVal = this.pageInfo?.meta_title_content
        this.metaDesVal = this.pageInfo?.meta_description_content
        loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);
        
        this.pageUrl = window?.location?.href;
        this.pageType = this.pageInfo?.metaOgType;
        pageMetaTypeURL(this.pageUrl, this.pageType);

        this.banner = Array.of(this.ContentSec?.[0]?.content_section_id) //banner section detail
        this.dropDownCategory = this.ContentSec?.[0]?.content_section_id?.subcontent_sections?.[0]?.sub_subcontent_sec_part //banner section detail
        this.dropDownTool = this.ContentSec?.[0]?.content_section_id?.subcontent_sections?.[1]?.sub_subcontent_sec_part //banner section detail
        // this.related = this.ContentSec?.[1]?.content_section_id?.subcontent_sections //banner section detail
        // this.relatedShow = this.ContentSec?.[1]?.content_section_id?.subcontent_sections //banner section detail
        // this.totalRec = this.related?.length;
        // for(let i=0; i<this.related?.length; i++){
        //   this.related[i].keywordsArray = this.related?.[i]?.keywords?.split(', ')
        //   this.relatedShow[i].keywordsArray = this.relatedShow?.[i]?.keywords?.split(', ')
        // }
      }
    });

    this.type = 'client_story';
    let blogCardPageData = this.transferState.useScullyTransferState('blogCardTS', this.data.getCard(this.type))
    blogCardPageData.subscribe((result)=>{
      // console.log('result',result);
      this.allDataBlog = result;
      if('/stories' === this.router?.url?.split('?')[0]){
        this.ContentSecStory = this.allDataBlog?.data ;
        this.related = this.ContentSecStory;
        this.relatedShow = this.ContentSecStory ;
        this.totalRec = this.ContentSecStory?.length;
        for(let i=0; i<this.ContentSecStory?.length; i++){
          this.related[i].keywordsArray = this.ContentSecStory?.[i]?.keyword?.split(', ');
          this.relatedShow[i].keywordsArray = this.ContentSecStory?.[i]?.keyword?.split(', ');
          //this.ContentSecStory[i].keywordsArray = this.ContentSecStory?.[i]?.keyword?.split(', ');
        }
      }
    });

  }

  ngOnDestroy(){
    let isMetaUrlAvailable = document.getElementById("metaURL");
    let isMetaTypeAvailable = document.getElementById("metaTYPE");
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }
  
  scrollTop(){
    window.scroll(0,0);
  }
}