import { Component} from '@angular/core';
// import { DataService } from './data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Brooklyn-Data';
  // flag: boolean = false;
  // constructor(private service: DataService,private changeDetector: ChangeDetectorRef) {
    // this.service?.footer.subscribe(() => {
    //   this.flag = true;
    //   this.changeDetector.detectChanges();
    // })
  //}
}
