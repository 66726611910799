<app-header-two></app-header-two>

<div class="thanku">
    <div class="container">
        <ng-container *ngFor="let item of page">
            <h3 *ngIf="item.content_section_id?.heading">{{item.content_section_id?.heading}}</h3>
            <p *ngIf="item.content_section_id?.description">{{item.content_section_id?.description}}</p>
            <a href="{{item.content_section_id?.button_link}}" class="btn btns">{{item.content_section_id?.button_label}}</a>
        </ng-container>
    </div>
</div>