import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import{ DataService } from './../../data.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TransferStateService } from '@scullyio/ng-lib';
declare var $:any;
declare function loadMeta(titVal:any,metaTitVal:any,metaDesVal:any ):any;
declare function pageMetaTypeURL(url: any, type: any): any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  allData: any;
  sections:any;
  banner:any;
  stackExperts:any
  approach:any;
  logo:any;
  BDC: any;
  solutionAndBDC: any;
  contact : any;
  testSec : any;
  banner_img:any;
  approach_img:any;
  titleValue:any='';
  metaTitVal:any='';
  metaDesVal:any='';
  pageUrl:any='';
  pageType:any='';
  
  imgURL= `${environment?.apiURL}/assets/`;
  pageInfo: any;

  constructor(private data:DataService , private router:Router, private transferState: TransferStateService ) { }
  
  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
    window.scroll(0,0);
  }

  ngOnInit(){
    let homePageData = this.transferState.useScullyTransferState('homeTS', this.data.getPageData(this.router?.url?.split('?')[0]));
    homePageData.subscribe((result)=>{
    this.allData = result;
    // console.log(this.allData)
    this.allData = this.allData?.data?.[0];
    // console.log(this.allData)
    // console.log(this.allData?.slug +" "+ this.router.url)
    if(this.allData?.slug == this.router?.url?.split('?')[0]){
      this.pageInfo = this.allData
      // console.log(this.pageInfo)
      this.titleValue = this.pageInfo?.title
      this.metaTitVal = this.pageInfo?.meta_title_content
      this.metaDesVal = this.pageInfo?.meta_description_content
      loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);
      this.pageUrl = window?.location?.href;
      this.pageType = this.pageInfo?.metaOgType;
      pageMetaTypeURL(this.pageUrl, this.pageType);

      this.banner = Array.of(this.allData?.content_sections?.[0]?.content_section_id);
      // this.stackExperts = Array.of(this.allData?.content_sections?.[1]?.content_section_id);
      this.approach = Array.of(this.allData?.content_sections?.[2]?.content_section_id);
      this.BDC = Array.of(this.allData?.content_sections?.[3]?.content_section_id);
      this.solutionAndBDC = Array.of(this.allData?.content_sections?.[4]?.content_section_id);
      this.contact = Array.of(this.allData?.content_sections?.[5]?.content_section_id);
      this.testSec = this.allData?.testimonial?.testimonials;
    }
    });
  }

  ngOnDestroy(){
    let isMetaUrlAvailable = document.getElementById("metaURL");
    let isMetaTypeAvailable = document.getElementById("metaTYPE");
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }
}
