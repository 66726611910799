import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { TransferStateService } from '@scullyio/ng-lib';
declare function loadMeta(titVal:any,metaTitVal:any,metaDesVal:any ):any;
declare function pageMetaTypeURL(url: any, type: any): any;
declare var $:any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  imgURL = `${environment.apiURL}/assets/`;
  allData:any;
  banner:any;
  philosphy:any;
  story:any;
  touch:any;
  people:any;
  BDC:any;
  titleValue:any='';
  metaTitVal:any='';
  metaDesVal:any='';
  pageInfo: any;
  pageUrl:any='';
  pageType:any='';
  isShown: boolean = true ; 

  rowIncr:any = 12;
  itemsStart:any;

  constructor(private data:DataService, private router: Router,  private transferState: TransferStateService) { }

  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
  }

  ngOnInit(): void {
    let aboutPageData = this.transferState.useScullyTransferState('aboutPageTS', this.data.getPageData(this.router?.url?.split('?')[0]))
    aboutPageData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url.split('?')[0]){
        this.pageInfo = this.allData
        // console.log(this.pageInfo)
        this.titleValue = this.pageInfo?.title
        this.metaTitVal = this.pageInfo?.meta_title_content
        this.metaDesVal = this.pageInfo?.meta_description_content
        loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);
        
        this.pageUrl = window?.location?.href?.split('?')[0];
        this.pageType = this.pageInfo?.metaOgType;
        pageMetaTypeURL(this.pageUrl, this.pageType);

        this.banner = Array.of(this.allData?.content_sections?.[0]?.content_section_id)
        this.story = Array.of(this.allData?.content_sections?.[1]?.content_section_id)
        this.philosphy = Array.of(this.allData?.content_sections?.[2]?.content_section_id)
        this.people = Array.of(this.allData?.content_sections?.[3]?.content_section_id)
        this.BDC = Array.of(this.allData?.content_sections?.[4]?.content_section_id)
        this.touch = Array.of(this.allData?.content_sections?.[5]?.content_section_id)
      }
    });
    this.toggleShow();
  }

  toggleShow() {
    // $(".fadeGradient").css("display", "none");
  }

  ngOnDestroy(){
    let isMetaUrlAvailable = document.getElementById("metaURL");
    let isMetaTypeAvailable = document.getElementById("metaTYPE");
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }
  showMore() {
    this.isShown = ! this.isShown;
    this.rowIncr += 12;
  }
}


