import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
declare function loadMeta(titVal: any, metaTitVal: any, metaDesVal: any): any;
declare function pageMetaTypeURL(url: any, type: any): any;

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent implements OnInit {
  imgURL = `${environment?.apiURL}/assets/`;
  allData: any;
  ContentSec: any;
  pageInfo: any;
  banner: any;
  related: any;
  relatedShow: any;
  dropDownCategory: any;
  dropDownTool: any;
  totalRec: any;
  page: number = 1;
  titleValue: any = '';
  metaTitVal: any = '';
  metaDesVal: any = '';
  pageUrl: any = '';
  pageType: any = '';
  filter = {
    category: '',
    tools: '',
  };
  allDataBlog: any = '';
  ContentSecBlog: any;
  type: any = '';
  keywordsArray: any = [];

  setFilter(fType: string, fValue: string) {
    if (fType === 'category') this.filter.category = fValue;
    else {
      this.filter.tools = fValue;
    }
    this.showFilter();
  }

  clearCategory() {
    this.filter.category = '';
    this.showFilter();
  }

  clearTool() {
    this.filter.tools = '';
    this.showFilter();
  }

  showFilter() {
    if (this.filter?.category == '' && this.filter?.tools == '') {
      this.relatedShow = this.related;
      this.totalRec = this.relatedShow?.length;
    }
    this.relatedShow = this.related?.filter((e: any) => {
      if (this.filter?.category !== '' && this.filter?.tools !== '') {
        if (
          e?.keywordsArray?.includes(this.filter?.category) &&
          e?.keywordsArray?.includes(this.filter?.tools)
        ) {
          return true;
        } else return false;
      } else if (this.filter?.category !== '') {
        if (e?.keywordsArray?.includes(this.filter?.category)) {
          return true;
        } else return false;
      } else if (this.filter.tools !== '') {
        if (e?.keywordsArray?.includes(this.filter?.tools)) {
          return true;
        } else return false;
      } else {
        return true;
      }
    });
    this.totalRec = this.relatedShow?.length;
  }

  constructor(
    private data: DataService,
    private router: Router,
    private transferState: TransferStateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.filter.category = params['category'] ?? '';
      this.filter.tools = params['tools'] ?? '';
    });
    let router = this.router.url?.split('?')?.[0];

    let blogPageData = this.transferState.useScullyTransferState(
      'blogTS',
      this.data.getPageData(router)
    );
    blogPageData.subscribe((result) => {
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ router)
      if (this.allData?.slug == router) {
        this.ContentSec = this.allData?.content_sections;
        this.pageInfo = this.allData;
        // console.log(this.pageInfo)
        this.titleValue = this.pageInfo?.title;
        this.metaTitVal = this.pageInfo?.meta_title_content;
        this.metaDesVal = this.pageInfo?.meta_description_content;
        loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);

        this.pageUrl = window?.location?.href;
        this.pageType = this.pageInfo?.metaOgType;
        pageMetaTypeURL(this.pageUrl, this.pageType);

        this.banner = Array.of(this.ContentSec?.[0]?.content_section_id); //banner section detail
        this.dropDownCategory =
          this.ContentSec?.[0]?.content_section_id?.subcontent_sections?.[0]?.sub_subcontent_sec_part;
        this.dropDownTool =
          this.ContentSec?.[0]?.content_section_id?.subcontent_sections?.[1]?.sub_subcontent_sec_part;
        // this.related = this.ContentSec?.[1]?.content_section_id?.subcontent_sections //banner section detail
        //this.relatedShow = this.ContentSec?.[1]?.content_section_id?.subcontent_sections //banner section detail
        // this.totalRec = this.related?.length;
        // for(let i=0; i<this.related?.length; i++){
        //   this.related[i].keywordsArray = this.related?.[i]?.keywords?.split(', ')
        //   this.relatedShow[i].keywordsArray = this.relatedShow?.[i]?.keywords?.split(', ')
        // }
      }
    });

    this.type = 'blog';
    let blogCardPageData = this.transferState.useScullyTransferState(
      'blogCardTS',
      this.data.getCard(this.type)
    );
    blogCardPageData.subscribe((result) => {
      this.allDataBlog = result;
      console.log('result', this.allDataBlog);
      if ('/blog' === this.router?.url.split('?')[0]) {
        this.ContentSecBlog = this.allDataBlog?.data;
        this.related = this.ContentSecBlog;
        this.relatedShow = this.ContentSecBlog;
        this.totalRec = this.ContentSecBlog?.length;
        for (let i = 0; i < this.related?.length; i++) {
          this.related[i].keywordsArray =
            this.ContentSecBlog?.[i]?.keyword?.split(', ');
          this.relatedShow[i].keywordsArray =
            this.ContentSecBlog?.[i]?.keyword?.split(', ');
        }
      }
      this.showFilter();
    });
  }
  scrollTop() {
    window.scroll(0, 0);
  }
}
