<section [class]="homepadtop" *ngIf="BDC">
    <div class="section2">
        <div class="container" *ngFor="let item of BDC">
            <h6 *ngIf="item?.additional_line">{{item.additional_line}}</h6>
            <h2 class="" *ngIf="item?.heading">{{item.heading}}</h2>
            <div class="row mt-lg-5 web-view">
                <div class="col-xl-4 col-lg-6" *ngFor="let sub_item of item?.subcontent_sections">
                    <div class="{{sub_item.class}}">
                        <img loading="lazy" *ngIf="sub_item.subcontent_logo"  src="{{imgURL}}{{sub_item?.subcontent_logo?.id}}" alt="{{sub_item.subcontent_logo_alt_text}}">
                        <h4 *ngIf="sub_item.sub_head">{{sub_item.sub_head}}</h4>
                        <p *ngIf="sub_item.sub_head_description">{{sub_item.sub_head_description}}</p>
                    </div>
                </div>
            </div>

            <!-- mobile starts  -->
            <owl-carousel-o [options]="customOptions" class="mobile-view">
                <ng-template carouselSlide *ngFor="let sub_item of item?.subcontent_sections">
                    <div class="slide">
                        <div class="card">
                            <img loading="lazy" *ngIf="sub_item.subcontent_logo" src="{{imgURL}}{{sub_item?.subcontent_logo?.id}}" alt="{{sub_item.subcontent_logo_alt_text}}">
                            <h4 *ngIf="sub_item.sub_head">{{sub_item.sub_head}}</h4>
                            <p *ngIf="sub_item.sub_head_description">{{sub_item.sub_head_description}}</p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>

        </div>
    </div>
</section>

<!-- mobile view -->