import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  imgURL = `${environment.apiURL}/assets/`
  allData:any;
  ContentSec:any;
  pageInfo:any;
  banner:any;
  heroCards:any;
  FeaturedTools:any;
  titleValue:any='';
  pageUrl:any='';
  pageType:any='';

  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  ngOnInit(){
    let ClientsData = this.transferState.useScullyTransferState('ClientsTS', this.data.getPageData(this.router?.url?.split('?')[0]));
    ClientsData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data[0];
      console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections;
        // this.pageInfo = this.allData
        this.pageInfo = this.allData
        this.titleValue = this.pageInfo?.title
        this.pageUrl = window?.location?.href;
        this.banner = Array.of(this.ContentSec?.[0]?.content_section_id); //banner section detail
        this.heroCards = this.ContentSec?.[1]?.content_section_id.subcontent_sections;
        this.FeaturedTools = Array.of(this.ContentSec?.[2]?.content_section_id);
      }
    })
  }

}