import { DOCUMENT } from '@angular/common';
import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { environment } from 'src/environments/environment';
import {DataService} from './../../data.service';
declare var $:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  datas:any;
  buttons:any;
  allData:any;
  logo:any;
  navigation : any;
  navIndex:any;
  urlActive:any;
  
  constructor(private sdata:DataService , private router: Router, private transferState: TransferStateService,
    @Inject(DOCUMENT) private document: Document ) { }
  
  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
    window.scroll(0,0);
  }
  
  imgURL= `${environment.apiURL}/assets/`;

  innerCard(i:number){
    this.navIndex = i ;
  }
    
  ngOnInit(){
    let headerData = this.transferState.useScullyTransferState('headerTS', this.sdata.getHeader());
    headerData.subscribe((result)=>{
      this.datas = result;
      this.allData = this.datas?.data?.[0];
      this.buttons = this.allData?.button_name;
      this.navigation = this.allData?.navigations;
      // console.log(this.allData)
      // console.log(this.navigation)
      this.logo = this.allData?.images;
    })

    this.urlActive = this.router?.url?.split('?')[0];
  }

  ngAfterViewInit(){
    
  }

  header_variable = false
  @HostListener("document:scroll")
  scrollfunction(){
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      this.header_variable = true
    } else {
      this.header_variable = false
 
    }
  }

  // function to go back and close mobile nav
  closeNav(){
    $('#close').click();
  }
  scrollTop(){
    window.scroll(0,0);
  }

  /* Open the sidenav */
openNav() {
  $('#mySidenav').addClass("w-100");
  if($('#mySidenav').hasClass("sidenav w-100")){
    this.document.body.classList.add('body-overflow');
  }
}
opensubNav() {
  $('.list').addClass("w-100");
}

/* Close/hide the sidenav */
closeNavnew() {
  $('#mySidenav').removeClass("w-100");
  if($('#mySidenav').hasClass("sidenav")){
    this.document.body.classList.remove('body-overflow');
  }
}

}
