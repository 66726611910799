import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import {DomSanitizer} from '@angular/platform-browser';
declare function loadLink(relVal:any, hrefVal:any):any;
declare function loadMeta(titVal:any,metaTitVal:any,metaDesVal:any ):any;
declare function loadMetaImage(imgLink: any): any;
declare function pageMetaTypeURL(url: any, type: any): any;
@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss']
})
export class CaseStudiesComponent implements OnInit {

  imgURL = `${environment?.apiURL}/assets/`
  allData:any;
  ContentSec:any;
  pageInfo:any;
  banner:any;
  contact:any;
  related: any;
  heading: any;
  summary:any;
  keywordsArray:any;
  url:any;
  embed_code_parse:any;
  text:any; 
  allDataContent:any;
  content_text:any;
  subcontent_text:any;
  sub_subcontent_text:any;
  linkRelValue:any='';
  linkHrefValue:any='';
  titleValue:any='';
  metaTitVal:any='';
  metaDesVal:any='';
  imgLink: any='';
  img: any='';
  pageUrl:any='';
  pageType:any='';

  constructor(private data:DataService, private sanitizer: DomSanitizer, private router: Router, private transferState: TransferStateService) { }
  
  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
    window.scroll(0,0);
  }

recieve(type:string){
  let updatedUrl = '';
  if(type ==='client_story'){
    updatedUrl = this.router?.url?.replace('/stories/', '');
  }
  if(type ==='blog'){
    updatedUrl = this.router?.url?.replace('/blog/', '');
  }

  let caseStudyPageData = this.transferState.useScullyTransferState('caseStudyTS', this.data.getCase(updatedUrl?.split('?')[0], type))
    caseStudyPageData.subscribe((result)=>{
    this.allData = result;
    this.allData = this.allData?.data?.[0];
    //console.log(this.allData)
    this.allDataContent = Array.of(this.allData);
    // console.log(this.allData?.slug +" "+ updatedUrl)
    if(this.allData?.slug == updatedUrl.split('?')[0]){
      this.ContentSec = this.allData?.content_sections
      
      //link tag values
      this.linkRelValue = this.allData?.link_rel_value;
      this.linkHrefValue = this.allData?.link_href_value;
      loadLink(this.linkRelValue, this.linkHrefValue);
      this.pageInfo = this.allData;
      // console.log(this.pageInfo);
      this.titleValue = this.pageInfo?.title;
      this.metaTitVal = this.pageInfo?.meta_title_content;
      this.metaDesVal = this.pageInfo?.meta_description_content;
      loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);
      this.img = this.pageInfo?.card_image?.filename_disk;
      this.imgLink = `${this.imgURL}${this.img}`;
      loadMetaImage(this.imgLink);

      this.pageUrl = window?.location?.href;
      this.pageType = this.pageInfo?.metaOgType;
      pageMetaTypeURL(this.pageUrl, this.pageType);

      // this.imgLink = ;
      // loadMetaImage(this.imgLink);
      // this.banner = Array.of(this.ContentSec?.[0]?.content_section_id) //banner section detail
      // this.summary = Array.of(this.ContentSec?.[1]?.content_section_id) //banner section detail
      // this.heading = Array.of(this.ContentSec?.[2]?.content_section_id) //banner section detail
      // this.contact = Array.of(this.ContentSec?.[3]?.content_section_id) //banner section detail
      // this.related = Array.of(this.ContentSec?.[4]?.content_section_id) //banner section detail
      this.allDataContent[0].keywordsArray= this.allDataContent?.[0]?.keyword?.split(', ')
      // for(let i=0; i<this.related?.[0]?.subcontent_sections.length; i++){
      //   this.related[0].subcontent_sections[i].keywordsArray= this.related?.[0]?.subcontent_sections[i]?.keywords?.split(', ')
      // }
      // for(let i=0;i<this.heading?.[0]?.subcontent_sections.length ; i++){
        //   this.text = this.heading?.[0]?.subcontent_sections?.[i]?.media
        //   this.heading[0].subcontent_sections[i].text = this.sanitizer.bypassSecurityTrustHtml(this.text);
        // }
        for(let i=0;i<this.allDataContent?.length ; i++){
          this.text = this.allDataContent?.[i]?.rich_description_wys
          this.allDataContent[i].text = this.sanitizer.bypassSecurityTrustHtml(this.text);
        }
        for(let i=0;i<this.allDataContent?.[0]?.content_sections.length ; i++){
          this.content_text = this.allDataContent?.[0]?.content_sections?.[i]?.content_section_id?.rich_discription
          this.allDataContent[0].content_sections[i].content_text = this.sanitizer.bypassSecurityTrustHtml(this.content_text);
          for(let j=0;j<this.allDataContent?.[0]?.content_sections?.[i]?.content_section_id?.subcontent_sections.length ; j++){
            this.subcontent_text = this.allDataContent?.[0]?.content_sections?.[i]?.content_section_id?.subcontent_sections?.[j]?.rich_description
            this.allDataContent[0].content_sections[i].content_section_id.subcontent_sections[j].subcontent_text = this.sanitizer.bypassSecurityTrustHtml(this.subcontent_text);
            
            for(let k=0;k<this.allDataContent?.[0]?.content_sections?.[i]?.content_section_id?.subcontent_sections?.[j]?.sub_subcontent_sec_part.length ; k++){
              this.sub_subcontent_text = this.allDataContent?.[0]?.content_sections?.[i]?.content_section_id?.subcontent_sections?.[j]?.sub_subcontent_sec_part?.[k]?.rich_description
              this.allDataContent[0].content_sections[i].content_section_id.subcontent_sections[j].sub_subcontent_sec_part[k].sub_subcontent_text = this.sanitizer.bypassSecurityTrustHtml(this.sub_subcontent_text);
            }
          }
        }
        
      }
    })
}
  
  ngOnInit() {
    window.scroll(0,0);
    this.url = this.router?.url?.split('?')[0];
    if(this.url.indexOf("stories") >=0 ){
      this.recieve('client_story')
    }else if(this.url.indexOf("blog") >=0){
      this.recieve('blog')
    }
  }
  ngOnDestroy(){
    loadLink('', '');
    let loadimage = document.getElementById("img_id");
    loadimage?.remove();
    let isMetaUrlAvailable = document.getElementById("metaURL");
    let isMetaTypeAvailable = document.getElementById("metaTYPE");
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }
  share(type:string){
    let url;
    switch(type){
      case "fb": 
        url = 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href;
        break;
      
      case "mail": 
        url = 'mailto:?subject=I wanted you to checkout this blog &;body=Check out this site ' + window.location.href;
        break;

      case "linkedin": 
        url = 'https://www.linkedin.com/sharing/share-offsite/?url=' + window.location.href;
        break;
    }
    if(url){
      window.open(url);
    }
  }
}
