import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  allData:any;
  page:any;
  allDataPage:any;

  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  ngOnInit(): void {
    let pageAvailablity = this.transferState.useScullyTransferState('pageAvailablityTS', this.data.getPageData(this.router?.url?.split('?')[0]));
    pageAvailablity.subscribe((result)=>{
    this.allData = result;
    // console.log(this.allData);
    // console.log(this.allData.data.length)
    // console.log(this.allData?.data?.[0])
    // console.log(this.router.url)
    if(this.allData?.data?.length === 0 || this.allData?.data?.[0]?.slug == this.router?.url?.split('?')[0] ){
      let pageNotAvailablity = this.transferState.useScullyTransferState('pageNotAvailablityTS', this.data.getPageData("/**"));
        pageNotAvailablity.subscribe((results)=>{
          this.allDataPage = results;
          // console.log(this.allDataPage?.data?.[0]?.content_sections?.[0]?.content_section_id);
          this.page = Array.of(this.allDataPage?.data?.[0]?.content_sections?.[0]?.content_section_id);
        })
    }
  });
  }

}
