import { Component,  OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
declare var $:any;
declare function loadMeta(titVal:any,metaTitVal:any,metaDesVal:any ):any;
declare function pageMetaTypeURL(url: any, type: any): any;

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit {

  imgURL = `${environment.apiURL}/assets/`
  allData:any;
  ContentSec:any;
  pageInfo:any;
  banner:any;
  heroCards:any;
  FeaturedTools:any;
  cBrowsing:any;
  contact:any;
  dataStack:any;
  titleValue:any='';
  metaTitVal:any='';
  metaDesVal:any='';
  pageUrl:any='';
  pageType:any='';

  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
    this.scrollTop();
  }

  ngOnInit() {
    let toolsData = this.transferState.useScullyTransferState('toolsTS', this.data.getPageData(this.router?.url?.split('?')[0]));
    toolsData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections;
        // this.pageInfo = this.allData
        this.pageInfo = this.allData
        this.titleValue = this.pageInfo?.title
        this.metaTitVal = this.pageInfo?.meta_title_content
        this.metaDesVal = this.pageInfo?.meta_description_content
        loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);
        
        this.pageUrl = window?.location?.href;
        this.pageType = this.pageInfo?.metaOgType;
        pageMetaTypeURL(this.pageUrl, this.pageType);

        this.banner = Array.of(this.ContentSec?.[0]?.content_section_id) //banner section detail
        this.heroCards = this.ContentSec?.[1]?.content_section_id.subcontent_sections 
        this.FeaturedTools = Array.of(this.ContentSec?.[2]?.content_section_id) 
        this.dataStack = Array.of(this.ContentSec?.[3]?.content_section_id) 
        this.cBrowsing = Array.of(this.ContentSec?.[4]?.content_section_id)
        this.contact = Array.of(this.ContentSec?.[5]?.content_section_id)
      }
    });
  } 

  ngOnDestroy(){
    let isMetaUrlAvailable = document.getElementById("metaURL");
    let isMetaTypeAvailable = document.getElementById("metaTYPE");
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }

  // for owl carousel
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    freeDrag:true,
    dots: false,
    navSpeed: 600,
    dotsEach: false,
    navText: ['<img loading="lazy"  src="assets/img/left-arrow.svg">','<img loading="lazy"  src="assets/img/right-arrow.svg">'],
    responsive: {
      0: {
        items: 1
      },
      325:{
        items: 1.2
      },
      650: {
        items: 2
      },
      1000: {
        items: 2
      },
      1200: {
        items: 2
      }
    },
    nav: false
  }
  
  scrollCategory(a:any){
    // console.log(a);
    if(!($(a).length>0))
    return;
    $('html, body').animate({
      scrollTop: ($(a).offset().top - 90)
    }, 400);
  }
  scrollTop(){
    window.scroll(0,0);
  }
  
}