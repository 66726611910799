import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import{ DataService } from './../../data.service';
import { TransferStateService } from '@scullyio/ng-lib';
import {DomSanitizer} from '@angular/platform-browser';
declare function loadMeta(titVal:any,metaTitVal:any,metaDesVal:any ):any;
declare function pageMetaTypeURL(url: any, type: any): any;

@Component({
  selector: 'app-general-page',
  templateUrl: './general-page.component.html',
  styleUrls: ['./general-page.component.scss']
})
export class GeneralPageComponent implements OnInit {
  imgURL= `${environment.apiURL}/assets/`;
  allData: any;
  sections:any;
  banner:any;
  headOne:any;
  headTwo:any;
  allDataContent:any;
  content_text:any;
  pageInfo: any;
  titleValue:any='';
  metaTitVal:any='';
  metaDesVal:any='';
  pageUrl:any='';
  pageType:any='';
​
  constructor(private data:DataService , 
              private router:Router, 
              private sanitizer: DomSanitizer,
              private transferState: TransferStateService 
              ) { }
​
  ngOnInit(): void {
    let generalPageData = this.transferState.useScullyTransferState('generalTS', this.data.getPageData(this.router?.url?.split('?')[0]));
    generalPageData.subscribe((result)=>{
    this.allData = result;
    // console.log(this.allData)
    this.allData = this.allData?.data?.[0];
    // console.log(this.allData)
    this.allDataContent = Array.of(this.allData);
    // console.log(this.allData?.slug +" "+ this.router.url)
    if(this.allData?.slug == this.router?.url?.split('?')[0]){
      this.pageInfo = this.allData
      // console.log(this.pageInfo)
      this.titleValue = this.pageInfo?.title
      this.metaTitVal = this.pageInfo?.meta_title_content
      this.metaDesVal = this.pageInfo?.meta_description_content
      loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);
      
      this.pageUrl = window?.location?.href;
      this.pageType = this.pageInfo?.metaOgType;
      pageMetaTypeURL(this.pageUrl, this.pageType);

      this.banner = Array.of(this.allData?.content_sections?.[0]?.content_section_id);
      this.headOne = Array.of(this.allData?.content_sections?.[1]?.content_section_id);
      this.headTwo = Array.of(this.allData?.content_sections?.[2]?.content_section_id);
      for(let i=0;i<this.allDataContent?.[0]?.content_sections.length ; i++){
        this.content_text = this.allDataContent?.[0]?.content_sections?.[i]?.content_section_id?.rich_discription
        
        this.allDataContent[0].content_sections[i].content_text = this.sanitizer.bypassSecurityTrustHtml(this.content_text);
      }
    }
    });
  }
  ngOnDestroy(){
    let isMetaUrlAvailable = document.getElementById("metaURL");
    let isMetaTypeAvailable = document.getElementById("metaTYPE");
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }
}