<!-- cards section starts here -->

<section class="cardSection" *ngIf="cardSlider?.length > 0">
     <div class="container" *ngFor="let item of cardSlider;let i=index;">
          <h2>{{item.heading}}</h2>
          <ng-container *ngIf="item.subcontent_sections">
               <owl-carousel-o [options]="customOptions" class="cardsCarousel">
                    <ng-template carouselSlide *ngFor="let subItem of item?.subcontent_sections">
                         <div class="slide">
                              <a href="{{subItem?.slug}}" class="homeCards">
                                   <img src="{{imgURL}}{{subItem?.subhead_images[i]?.directus_files_id?.id}}" alt="stack expert">
                              </a>
                         </div>
                    </ng-template>
                </owl-carousel-o>
          </ng-container>
     </div>
</section>

<!-- Section 1 Starts  -->