import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TransferStateService } from '@scullyio/ng-lib';
declare function loadMeta(titVal:any,metaTitVal:any,metaDesVal:any ):any;
declare function pageMetaTypeURL(url: any, type: any): any;
declare var $:any;

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  imgURL = `${environment.apiURL}/assets/`
  allData:any;
  ContentSec:any;
  pageInfo:any;
  diversity:any;
  ourApproach:any;
  whereWe:any;
  Statistics:any;
  culture:any;
  connect: any;
  values:any;
  BDC:any;
  titleValue:any='';
  metaTitVal:any='';
  metaDesVal:any='';
  banner:any;  
  pageUrl:any='';
  pageType:any='';
  element: any;
  mybutton:any;
  joinTeam:any;
  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  // for owl carousel
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    freeDrag:true,
    dots: false,
    navSpeed: 600,
    navText: [ ],
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      760: {
        items: 3
      },
      1000: {
        items: 4
      }
    },
    nav: false
  }

  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
  }

  ngOnInit() {
    let careerPageData = this.transferState.useScullyTransferState('careerTS', this.data.getPageData(this.router?.url?.split('?')[0]))
    careerPageData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data[0];
      // console.log(this.allData)
      // console.log(this.allData.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections
        this.pageInfo = this.allData
      // console.log(this.pageInfo)
      this.titleValue = this.pageInfo?.title 
      this.metaTitVal = this.pageInfo?.meta_title_content
      this.metaDesVal = this.pageInfo?.meta_description_content
      loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);
      this.pageUrl = window?.location?.href;
      this.pageType = this.pageInfo?.metaOgType;
      pageMetaTypeURL(this.pageUrl, this.pageType);

      this.banner = Array.of(this.ContentSec?.[0]?.content_section_id) //banner section detail
      this.values = Array.of(this.ContentSec?.[1]?.content_section_id)
      this.culture = Array.of(this.ContentSec?.[2]?.content_section_id)
      this.Statistics = this.ContentSec?.[3]?.content_section_id.subcontent_sections
      this.whereWe = Array.of(this.ContentSec?.[4]?.content_section_id) //where We are section detail
      this.ourApproach = Array.of(this.ContentSec?.[5]?.content_section_id) //where We are section detail
      this.diversity = Array.of(this.ContentSec?.[6]?.content_section_id) //where We are section detail
      
      this.connect = Array.of(this.ContentSec?.[8]?.content_section_id) //connect section detail
      // this.BDC = Array.of(this.ContentSec?.content_section_id)
      } 
    });
    
    $(window).scroll(() => {
      this.scrollFunction();
     });
  }
  scrollToCareer(element: any) {
    document.querySelector(element).scrollIntoView({ behavior: 'smooth', block: 'start' });
    // $(".floating_btn").css("display", "none");
  }
    
    scrollFunction() {
      this.mybutton = document.getElementById("floating_btn");
      this.joinTeam = document.getElementById("whyLast");
      
      console.log(this.joinTeam?.offsetTop);
      
      if (document.body?.scrollTop > this.joinTeam?.offsetTop || document.documentElement?.scrollTop > this.joinTeam?.offsetTop) {
        $(".floating_btn").css("display", "none");
      } else {
        $(".floating_btn").css("display", "block");
      }
    }


  ngOnDestroy(){
    let isMetaUrlAvailable = document.getElementById("metaURL");
    let isMetaTypeAvailable = document.getElementById("metaTYPE");
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }
}