 <!-- Testimonial starts here  -->


 <section class="testimonial" *ngIf="testSec?.length >= 2">
    <div class="container">
        <div class="testimonial-content">
            <ul class="nav nav-tabs"  role="tablist">
                <li class="nav-item" (click)="clearTime(i+1)" *ngFor="let item of testSec; let i=index;">
                    <a id="q{{i+1}}" class="{{item.class}}" data-toggle="tab" href="#tab{{i+1}}" *ngIf="item?.company_logo">
                        <img loading="lazy"  src="{{imgURL}}{{item.company_logo?.id}}" alt="img">
                    </a>
                </li>
            </ul>
            
            <div class="tab-content">
                <ng-container *ngFor="let item of testSec; let i=index;">
                    <div id="tab{{i+1}}" class="{{item.tab_class}}">
                        
                        <div class="row">
                            <div class="col-lg-10 col-9" >
                                <h4 *ngIf="item?.comment">{{item.comment}}</h4>
                                <div class="client">
                                    <img loading="lazy" *ngIf="item?.user_icon" src="{{imgURL}}{{item.user_icon?.id}}" alt="{{item?.user_icon_alt_text}}">
                                    <div class="{{item.user_class}}">
                                        <h6 *ngIf="item?.user_name">{{item.user_name}}</h6>
                                        <p *ngIf="item?.user_role">{{item.user_role}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-3">
                                <div class="btn__parent">
                                    <ng-container >
                                        <button (click)="clearTime(i);changetab('#q'+((i==0)?testSec.length:(i)))" class="tab__navigation__btn">
                                            <img loading="lazy" onerror="this.style.display='none'" class="tab__navigation__image"
                                            src="../../../assets/img/left-arrow.svg" alt="image">
                                        </button>
                                    </ng-container>
                                    <button (click)="clearTime(i+2);changetab('#q'+((i==testSec.length-1)?1:(i+2)))" class="tab__navigation__btn">
                                        <svg height="50" width="50" class="svgCircle">
                                            <circle cx="24" cy="24" r="23.4" stroke="#114F5C" stroke-width="1" fill="#fff"/>
                                        </svg>
                                        <img loading="lazy" onerror="this.style.display='none'" class="tab__navigation__image" src="../../../assets/img/right-arrow.svg" alt="left-arrow">
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="text-right">
                            <button *ngIf="item?.button_link_text" (click)="goToPage(item.slug)"
                                class="btn btns">{{item.button_link_text}}</button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</section>

<!-- Testimonial ends here -->

<!-- mobile-testimonial starts -->


<section class="testimonial-mobile" *ngIf="testSec?.length >= 2">
    <div class="container">
         

        <!-- card -->
        
        <owl-carousel-o [options]="customOptions" class="web-none">
            <ng-template carouselSlide *ngFor="let item of testSec; let i=index;">
                <div class="slide">
                    

                    <div class="card">
                        <!-- next and prev options -->
                        <div class="svg-mob-circle-left" (click)="prev()">
                            <svg height="44" width="44" class="svgCircle">
                                <circle cx="21" cy="21" r="21.4"  fill="transparent"/>
                            </svg>
                            <img loading="lazy" onerror="this.style.display='none'" class="tab__navigation__image" src="../../../assets/img/left-arrow.svg" alt="left-arrow">
                        </div>
                        <div class="svg-mob-circle-right" (click)="next()">
                            <svg height="44" width="44" class="svgCircle">
                                <circle cx="21" cy="21" r="21.4" stroke="#114F5C" stroke-width="3" fill="#fff"/>
                            </svg>
                            <img loading="lazy" onerror="this.style.display='none'" class="tab__navigation__image" src="../../../assets/img/right-arrow.svg" alt="right-arrow">
                        </div>
                        <img loading="lazy" *ngIf="item?.company_logo" src="{{imgURL}}{{item?.company_logo?.id}}" alt="{{item.company_logo_alt_text}}">
                        <h4 *ngIf="item?.comment">{{item.comment}}</h4>
                        <div class="client-info">
                            <img loading="lazy" *ngIf="item?.user_icon" src="{{imgURL}}{{item?.user_icon?.id}}" alt="{{item?.user_icon_alt_text}}">
                            <div>
                                <h6 *ngIf="item?.user_name">{{item.user_name}}</h6>
                                <p *ngIf="item?.user_role">{{item.user_role}}</p>
                            </div>
                        </div>
                        <button class="btn btns" (click)="goToPage(item.slug)" *ngIf="item?.button_link_text">{{item.button_link_text}}</button>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>

<!-- ((i+1)%testSec.length)+1-->