import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TransferStateService } from '@scullyio/ng-lib';
declare function loadMeta(titVal:any,metaTitVal:any,metaDesVal:any ):any;
declare function pageMetaTypeURL(url: any, type: any): any;

@Component({
  selector: 'app-solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.scss']
})
export class SolutionComponent implements OnInit {

  imgURL = `${environment.apiURL}/assets/`;
  allData:any;
  banner:any;
  BDC:any;
  contact:any;
  formSubmitted = false;
  form2clicked = false;
  pageInfo: any;
  titleValue:any='';
  metaTitVal:any='';
  metaDesVal:any='';  
  pageUrl:any='';
  pageType:any='';
constructor(private data:DataService, private router: Router, private formBuilder: FormBuilder, private httpClient: HttpClient, private transferState: TransferStateService) { }
  
checkoutForm = this.formBuilder.group({
  uName: ['',[Validators.required]],
  uEmail: ['', [
                  Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
                ],
          ],
  uCompany: ['',[Validators.required]],
  uRole: ['',[Validators.required]],
  uMessage: ['',[Validators.required]],
  uCheck: ''         
});

headers = new HttpHeaders(
  {'Content-Type': 'application/json', 
    // 'Access-Control-Allow-Origin': ['*'], 
    // 'Access-Control-Allow-Credentials': 'true',
    // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, PATCH'
  });

checkInput(){
  let value = false;
  Object.keys(this.checkoutForm.controls).forEach((key:any) => {
    const controlErrors: any = this.checkoutForm.get(key)?.errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach(keyError => {
        if(keyError=="required"){
          value=true;
        }
      });
    }
  });
  return value;
}

async emailSend(details:any){
  return this.httpClient.post(`${environment.apiURL}/send-email/?access_token=${environment.token}`,
                          {
                            name: details.uName,
                            email: details.uEmail,
                          },
                          {headers:this.headers}
                        )
                      .subscribe((data => { 
                        // console.log(data); 
                      }))
}

async onClickSubmit(info:any){
  this.form2clicked = true;
  if(this.checkoutForm.value.uEmail == '' || this.checkoutForm.controls.uEmail.errors){
    return;
  }
  else if(this.checkoutForm.value.uName == '' || this.checkoutForm.controls.uName.errors){
    return;
  }
  else if(this.checkoutForm.value.uCompany == '' || this.checkoutForm.controls.uCompany.errors){
    return;
  }
  else if(this.checkoutForm.value.uRole == '' || this.checkoutForm.controls.uRole.errors){
    return;
  }
  else if(this.checkoutForm.value.uMessage == '' || this.checkoutForm.controls.uMessage.errors){
    return;
  }
  this.httpClient.post(
    `${environment.apiURL}/items/contact_detail/?access_token=${environment.token}`,
        {
          name: info.uName,
          email: info.uEmail,
          company: info.uCompany,
          role: info.uRole,
          message: info.uMessage,
          subscriber: info.uCheck,
        },
        {headers:this.headers}
  ).subscribe((data) => { 
    // console.log(data); 
    this.emailSend(this.checkoutForm.value).then(()=> {
      this.formSubmitted = true;
      window.open("/thank-you", "_self");
    });
  })
}

  ngOnInit() {
    let soltionPageData = this.transferState.useScullyTransferState('soltionPageTS', this.data.getPageData(this.router?.url?.split('?')[0]))
    soltionPageData.subscribe((result)=>{
    this.allData = result;
    this.allData = this.allData?.data[0];
    // console.log(this.allData)
    // console.log(this.allData?.slug +" "+ this.router.url)
    if(this.allData?.slug == this.router?.url?.split('?')[0]){
      this.pageInfo = this.allData
      this.titleValue = this.pageInfo?.title
      this.metaTitVal = this.pageInfo?.meta_title_content
      this.metaDesVal = this.pageInfo?.meta_description_content
      loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);
      this.pageUrl = window?.location?.href;
      this.pageType = this.pageInfo?.metaOgType;
      pageMetaTypeURL(this.pageUrl, this.pageType);
      this.banner = Array.of(this.allData?.content_sections?.[0]?.content_section_id)
      this.BDC = Array.of(this.allData?.content_sections?.[1]?.content_section_id)
      this.contact = Array.of(this.allData?.content_sections?.[2]?.content_section_id)
    }
    });
  }

  ngOnDestroy(){
    let isMetaUrlAvailable = document.getElementById("metaURL");
    let isMetaTypeAvailable = document.getElementById("metaTYPE");
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }
  
// for owl carousel
customOptions: OwlOptions = {
  loop: true,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  freeDrag:true,
  dots: false,
  dotsEach: false,
  navSpeed: 600,
  navText: ['<img loading="lazy"  src="assets/img/left-arrow.svg">','<img loading="lazy"  src="assets/img/right-arrow.svg">'],
  responsive: {
    0: {
      items: 1 
    },
    325:{
      items: 1.3
    },
    500: {
      items: 2
    },
    768: {
      items: 2
    },
    1000: {
      items: 4
    }
  },
  nav: false
}

}
