<footer id="footer" [ngStyle]="{'background-color': '#29362F'}">
    <div class="footer-up" *ngIf="navigation">
        <div class="row mt-4">
            <div class="col-lg-9 col-md-12 col-12 mb-lg-0 mb-4 footer-web">
                <div class="footerLinks">
                    <div class="footer-nav" *ngFor="let nav of navigation">
                        <h5 *ngIf="nav.f_navigation_name">{{nav?.f_navigation_name}}</h5>
                        <ul>
                            <li *ngFor="let subNav of nav?.sub_navigation_ref">
                                <a href="{{subNav?.slug}}" *ngIf="subNav.footer_sub_nav_name">{{subNav?.footer_sub_nav_name}}</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-3 col-6 mb-lg-0 mb-4 footer-mob" *ngFor="let nav of navigation">
               
                    <h5 *ngIf="nav?.f_navigation_name">{{nav?.f_navigation_name}}</h5>
                    <ul>
                        <li *ngFor="let subNav of nav?.sub_navigation_ref">
                            <a href="{{subNav?.slug}}" *ngIf="subNav?.footer_sub_nav_name">{{subNav?.footer_sub_nav_name}}</a>
                        </li>

                    </ul>
                
            </div>
            
            <div class="col-lg-3 col-md-12 col-12 mb-lg-0 mb-4">
                <div class="newsletter" *ngIf="!formSubmitted">
                    <h4>Subscribe to our newsletter</h4>
                    <form [formGroup]="checkoutForm" #subForm
                        (ngSubmit)="onClickSubmit(checkoutForm.value)">
                            <div class="form-group">
                                <input class="input" type="email" id="email" placeholder="Email" name="uEmail" formControlName="uEmail" autocomplete="false">
                                <button class="btn subscribe" type="submit">Subscribe <img loading="lazy" onerror="this.style.display='none'" class="arrow__subscription" alt="arrow" src="assets/img/right-arrow-white.svg"></button>
                            </div>
                    </form>
                </div>
                <div>
                    <p class="error-text" *ngIf="form1Clicked && (checkoutForm.controls.uEmail.errors?.pattern || checkoutForm.controls.uEmail.errors?.required)">Incorrect Email. Please try again. </p>
                </div>
                <div *ngIf="formSubmitted">
                    <h1 class="success-text">Thank you for the submission!</h1>
                </div>
                <div class="social-icons-mobile">
                    <a>
                        <img loading="lazy" alt="Linkedin" onerror="this.style.display='none'" src="assets/img/social-icon/Linked in.png">
                    </a>
                    <a>
                        <img loading="lazy" alt="Twitter" onerror="this.style.display='none'" src="assets/img/social-icon/Twitter.png">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="footer-down">
        <div class="d-flex justify-content-between">
            <ng-container *ngFor="let item of navigation; let i = index">
                <div class="copyright" *ngIf="i===4" >
                <!-- <p><span>{{item?.extra_link_text}}</span> {{item?.keywords}}</p> -->
                <p><span>Copyright {{ UpdateYear }} All Rights Reserved</span> Legal</p>
                
                </div>
            </ng-container>
            <div class="social-icons">
                <a href="https://www.linkedin.com/company/brooklyndata" target="_blank">
                    <img loading="lazy" onerror="this.style.display='none'" alt="Linkedin" src="assets/img/social-icon/Linked in.png">
                </a>
                <a href="https://twitter.com/BrooklynData" target="_blank">
                    <img loading="lazy" onerror="this.style.display='none'" alt="Twitter" src="assets/img/social-icon/Twitter.png">
                </a>
            </div>
        </div>
    </div>
    
</footer>