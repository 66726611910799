<app-header-two></app-header-two> 
<div class="bg">
    <div class="about">
        <div class="container">
            <ng-container *ngFor="let item of banner">
                <h1 *ngIf="item.name" >{{item?.name}}</h1>
                <p *ngIf="item.heading">{{item?.heading}}</p>
            </ng-container>
            <div class="hero">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-12 order-lg-1 order-sm-1 order-2">
                        <div class="hero-img">
                            <ng-container *ngFor="let item of story">
                                <img alt="about-img" *ngIf="item.image" src="{{imgURL}}{{item.image?.id}}">
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-12 order-lg-2 order-sm-2 order-1">
                        <div class="hero-content">
                            <ng-container *ngFor="let item of story">
                                <h2 *ngIf="item.name">{{item?.name}}</h2>
                                <img loading="lazy" onerror="this.style.display='none'"  alt="border" class="divider" src='../../../assets/img/border.svg'>
                                <p *ngIf="item.description">{{item?.description}}</p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- philosphy section web view-->

    <div class="philosphy">
        <div class="container">
            <ng-container *ngFor="let item of philosphy">
                <h2 *ngIf="item.name">{{item?.name}}</h2>
                <div class="row">
                    <ng-container *ngFor="let sub of item?.subcontent_sections">
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                            <div class="card">
                                <img src="{{imgURL}}{{sub?.subcontent_logo?.id}}" alt="card-img">
                                <h3 *ngIf="sub?.sub_head">{{sub?.sub_head}}</h3>
                                <p *ngIf="sub?.sub_head_description">{{sub?.sub_head_description}} </p>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <ng-container *ngFor="let item of philosphy">
        <h2 class="philosphy-mob-heading" *ngIf="item.name">{{item?.name}}</h2>
    </ng-container>
    <div class="philosphy-mob">
        <!-- <div class="container"> -->
            <ng-container *ngFor="let item of philosphy">
                <div class="cards">
                    <ng-container *ngFor="let sub of item?.subcontent_sections">
                        <div class="card">
                            <img src="{{imgURL}}{{sub?.subcontent_logo?.id}}" alt="card-img">
                            <h3 *ngIf="sub?.sub_head">{{sub?.sub_head}}</h3>
                            <p *ngIf="sub?.sub_head_description">{{sub?.sub_head_description}} </p>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        <!-- </div> -->
    </div>


    <!-- team-section -->

    <div class="team-section">
        <div class="container">
            <ng-container *ngFor="let item of people">
                <h2 *ngIf="item.name">{{item?.name}}</h2>
                <p *ngIf="item.description">{{item?.description}}</p>
        
                <div class="team-members">
                    <div class="fadeGradient"></div>
                    <div class="row">
                        <ng-container *ngFor="let sub of item?.subcontent_sections; let i=index;">
                            <div class="col-xl-3 col-lg-4 col-md-6 col-6" *ngIf="i<12">
                                <div class="member">
                                    <img alt="Team member" *ngIf="sub?.subcontent_logo" src="{{imgURL}}{{sub?.subcontent_logo?.id}}">
                                    <h4 *ngIf="sub?.user1_name">{{sub?.user1_name}}</h4>
                                    <h6 *ngIf="sub?.keywords">{{sub?.keywords}}</h6>
                                    <h5 *ngIf="sub?.user1_role">{{sub?.user1_role}}</h5>
                                </div>
                            </div>
                            
                            <div class="col-xl-3 col-lg-4 col-md-6 col-6" *ngIf="i >= 12 && i < rowIncr">
                                <div class="member">
                                    <img alt="Team member" *ngIf="sub?.subcontent_logo" src="{{imgURL}}{{sub?.subcontent_logo?.id}}">
                                    <h4 *ngIf="sub?.user1_name">{{sub?.user1_name}}</h4>
                                    <h6 *ngIf="sub?.keywords">{{sub?.keywords}}</h6>
                                    <h5 *ngIf="sub?.user1_role">{{sub?.user1_role}}</h5>
                                </div>
                            </div>
                        </ng-container>
                        
                    </div>
                    
                </div>
                <div  class="col-xl-12 col-md-12 col-12 m-auto text-center">
                    <a class="showMember" (click)="showMore()">Load More </a>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- displaying brands -->
    <div class="brands">
        <h2></h2>
        <div class="brandsImgs">
            <img src="" alt="">
        </div>
    </div>

    <!-- more about BDC -->

    <div class="about-bdc">
        <div class="cards-section">
            <ng-container *ngFor="let item of BDC">
                <h6 *ngIf="item.name">{{item.name}}</h6>
                <h2 *ngIf="item.heading">{{item.heading}}</h2>
                <div class="container">
                    <div class="row">
                        <ng-container *ngFor="let sub of item?.subcontent_sections">
                            <div class="col-lg-4 col-sm-6 col-12">
                                <a href="{{sub?.extra_link}}" style="text-decoration: none;">
                                    <div class="card mt-lg-0 mt-4">
                                        <h5 *ngIf="sub?.sub_head">{{sub?.sub_head}}</h5>
                                        <p *ngIf="sub?.sub_head_description">{{sub?.sub_head_description}} </p>
                                        <a class="learn-more" href="{{sub.extra_link}}" *ngIf="sub?.extra_link_text">
                                            {{sub.extra_link_text}}
                                            <img onerror="this.style.display='none'" src="assets/img/right-arrow.svg" class="arrow" alt="arrow">
                                        </a>
                                    </div>
                                </a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="contact">
            <ng-container *ngFor="let item of touch">
                <h6 *ngIf="item.name">{{item?.name}}</h6>
                <h2 *ngIf="item.heading">{{item?.heading}}</h2>
                <button class="btn btns" (click)="goToPage(item?.button_link)"  *ngIf="item.button_label">{{item?.button_label}}</button>
            </ng-container>
        </div>
    </div>
</div>
