
<section *ngIf="buttonNavigation">
    <div class="page-swip">
        <div class="custom--container" >
            <div class="custom--container" *ngFor="let item of buttonNavigation">
                <h4 *ngIf="item?.heading">{{item.heading}} </h4>
                <h4 *ngIf="item?.name">{{item.name}}</h4>
                <div class="controls">
                    <div class="grid g-col-2">
                        <ng-container *ngFor="let sub_item of item?.subcontent_sections; let i=index">
                            <div class="prev" *ngIf="i===0">
                                <a href="{{sub_item.extra_link}}" aria-label="navigation" style="text-decoration: none;">
                                    <div class="prev__card">
                                        <p class="prev__subtitle" *ngIf="sub_item.sub_head">{{sub_item?.sub_head}}</p>
                                        <div class="d-flex">
                                            <!-- <div> -->
                                                <a href="{{sub_item.extra_link}}" aria-label="navigation">
                                                    <img loading="lazy" onerror="this.style.display='none'" class="prev__img" src="assets/img/left-arrow.png" alt="prev">
                                                </a>
                                            <!-- </div> -->
                                            <div class="mx-auto">
                                                <a *ngIf="sub_item.extra_link_text" class="prev__link" aria-label="navigation"
                                                    href="{{sub_item.extra_link}}">{{sub_item?.extra_link_text}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let sub_item of item?.subcontent_sections; let i=index">
                            <div class="next" *ngIf="i===1">
                                <a href="{{sub_item.extra_link}}" aria-label="navigation" style="text-decoration: none;">
                                    <div class="next__card">
                                        <div>
                                            <p class="next__subtitle" *ngIf="sub_item.sub_head">{{sub_item?.sub_head}}</p>
                                            <div class="d-flex">
                                                <div class="mx-auto">
                                                    <a class="next__link" aria-label="navigation" *ngIf="sub_item.extra_link_text"
                                                        href="{{sub_item.extra_link}}">{{sub_item?.extra_link_text}}</a>
                                                </div>
                                                
                                                    <a href="{{sub_item.extra_link}}" aria-label="navigation">
                                                        <img loading="lazy" onerror="this.style.display='none'" class="next__img" src="assets/img/right-arrow.png" alt="next">
                                                    </a> 
                                                
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>