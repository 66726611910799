import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';

@Component({
  selector: 'app-contact-alt',
  templateUrl: './contact-alt.component.html',
  styleUrls: ['./contact-alt.component.scss']
})
export class ContactAltComponent implements OnInit {
  imgURL = `${environment.apiURL}/assets/`
  allData:any;
  ContentSec:any;
  pageInfo:any;
  contactSec:any;

  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
    this.scrollTop();
  }
  
  ngOnInit(): void {
    let contactData = this.transferState.useScullyTransferState('relatedData', this.data.getPageData(this.router?.url?.split('?')[0]));
    contactData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections;
        for(let i=0; i<=this.ContentSec.length ; i++){
          if(this.ContentSec?.[i]?.content_section_id?.common_name === "WORK WITH US" ){
            this.contactSec = Array.of(this.ContentSec?.[i]?.content_section_id);
          }
        }
      }        
    })
  }
  scrollTop(){
    window.scroll(0,0);
  }
  

}
