import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
declare var $:any;

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {
  imgURL = `${environment.apiURL}/assets/`
  allData:any;
  testSec:any;
  pageInfo:any;
  tabChange= 1;
  slider:any;
  // for owl carousel
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    freeDrag:true,
    dots: true,
    autoplay:true,
    autoplayTimeout:7000,
    smartSpeed: 0,
    // navSpeed: 600,
    navText: [
      '<img loading="lazy" alt="left-arrow"  src="../../../assets/img/left-arrow.png" class="testimonial-arrows prev-custom">',
      '<img loading="lazy" alt="right-arrow" src="../../../assets/img/right-arrow.png" class="testimonial-arrows next-custom"> '
    ],
    responsive: {
      0: {
        items: 1 
      },
      700: {
        items: 1
      },
      1000: {
        items: 1
      }
    },
    nav: true
  }
  
  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
    window.scroll(0,0); // to scroll to page top when button clicked
  }
  ngAfterViewInit() {
    this.sliderTime();
  }
  ngOnInit(): void {
    let servicePageData = this.transferState.useScullyTransferState('serviceTS', this.data.getPageData(this.router?.url?.split('?')[0]));
    servicePageData.subscribe((result)=>{
    this.allData = result;
    this.allData = this.allData?.data?.[0];
    // console.log(this.allData)
    // console.log(this.allData?.slug +" "+ this.router.url)
    if(this.allData?.slug == this.router?.url?.split('?')[0]){
      this.pageInfo = this.allData
      this.testSec = this.pageInfo?.testimonial?.testimonials
      }        
    })
  }

  sliderTime(){
    this.slider = setInterval(() =>{
      let q = this.tabChange%this.testSec?.length;
      if(q==0){
        this.tabChange=1;
      }
      let str = '#q' + (q+1);
      this.tabChange ++;
      this.changetab(str);
    },7000);
  }

  changetab(a:any){
    let str = ".testimonial-content " + a;
    //console.log(str)
    $(str).click();
    
  }
  clearTime(b:any){
    clearInterval(this.slider);
    //console.log(b);
    this.tabChange = b;
    this.sliderTime();
  }


  // mobile next and prev owl button triggers

  next(){
    $('.svg-mob-circle-right').click(function() {
      $('.next-custom').trigger('click');
    })
  }
  prev(){
    $('.svg-mob-circle-left').click(function() {
      $('.prev-custom').trigger('click');
    })
  }
  
}
