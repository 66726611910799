import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TransferStateService } from '@scullyio/ng-lib';
declare function loadMeta(titVal: any, metaTitVal: any, metaDesVal: any): any;
declare function pageMetaTypeURL(url: any, type: any): any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  imgURL = `${environment.apiURL}/assets/`;
  allData: any;
  contact: any;
  ContentSec: any;
  response: any;
  formSubmitted = false;
  form2clicked = false;
  titleValue: any = '';
  metaTitVal: any = '';
  metaDesVal: any = '';
  pageInfo: any;
  pageUrl: any = '';
  pageType: any = '';

  constructor(
    private data: DataService,
    private router: Router,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private transferState: TransferStateService
  ) {}

  checkoutForm = this.formBuilder.group({
    uName: ['', [Validators.required]],
    uEmail: [
      '',
      [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
      ],
    ],
    uCompany: ['', [Validators.required]],
    uRole: ['', [Validators.required]],
    uMessage: ['', [Validators.required]],
    uCheck: '',
  });

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': ['*'],
    // 'Access-Control-Allow-Credentials': 'true',
    // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, PATCH'
  });

  checkInput() {
    let value = false;
    Object.keys(this.checkoutForm.controls).forEach((key: any) => {
      const controlErrors: any = this.checkoutForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          if (keyError == 'required') {
            value = true;
          }
        });
      }
    });
    return value;
  }

  async emailSend(details: any) {
    return this.httpClient
      .post(
        `${environment.apiURL}/send-email/?access_token=${environment.token}`,
        {
          name: details.uName,
          email: details.uEmail,
          company: details.uCompany,
          role: details.uRole,
          message: details.uMessage,
        },
        { headers: this.headers }
      )
      .subscribe((data) => {
        // console.log(data);
      });
  }

  async onClickSubmit(info: any) {
    this.form2clicked = true;
    if (
      this.checkoutForm.value.uEmail == '' ||
      this.checkoutForm.controls.uEmail.errors
    ) {
      return;
    } else if (
      this.checkoutForm.value.uName == '' ||
      this.checkoutForm.controls.uName.errors
    ) {
      return;
    } else if (
      this.checkoutForm.value.uCompany == '' ||
      this.checkoutForm.controls.uCompany.errors
    ) {
      return;
    } else if (
      this.checkoutForm.value.uRole == '' ||
      this.checkoutForm.controls.uRole.errors
    ) {
      return;
    } else if (
      this.checkoutForm.value.uMessage == '' ||
      this.checkoutForm.controls.uMessage.errors
    ) {
      return;
    }
    this.httpClient
      .post(
        `${environment.apiURL}/items/contact_detail/?access_token=${environment.token}`,
        {
          name: info.uName,
          email: info.uEmail,
          company: info.uCompany,
          role: info.uRole,
          message: info.uMessage,
          subscriber: info.uCheck,
        },
        { headers: this.headers }
      )
      .subscribe((data) => {
        this.emailSend(this.checkoutForm.value).then(() => {
          this.formSubmitted = true;
          window.open('/thank-you', '_self');
        });
      });
  }

  ngOnInit() {
    let contactPageData = this.transferState.useScullyTransferState(
      'contactTS',
      this.data.getPageData(this.router?.url?.split('?')[0])
    );
    contactPageData.subscribe((result) => {
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if (this.allData?.slug == this.router?.url?.split('?')[0]) {
        this.ContentSec = this.allData?.content_sections;
        // console.log(this.ContentSec?.[0]?.content_section_id)
        this.contact = Array.of(this.ContentSec?.[0]?.content_section_id);
        this.pageInfo = this.allData;
        this.titleValue = this.pageInfo?.title;
        this.metaTitVal = this.pageInfo?.meta_title_content;
        this.metaDesVal = this.pageInfo?.meta_description_content;
        loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);

        this.pageUrl = window?.location?.href;
        this.pageType = this.pageInfo?.metaOgType;
        pageMetaTypeURL(this.pageUrl, this.pageType);
      }
    });
  }
  ngOnDestroy() {
    let isMetaUrlAvailable = document.getElementById('metaURL');
    let isMetaTypeAvailable = document.getElementById('metaTYPE');
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }
}
