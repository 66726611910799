<section *ngIf="storyData?.length > 0">
    <div class="blogs">
        <div class="container">
            <div class="blogs-section" *ngFor="let item of relatedCase">
                <div class="blogs-heading">
                    <h2 *ngIf="item?.heading">{{item?.heading}}</h2>
                    <a href="{{item.slug}}" *ngIf="item?.extra_link">{{item?.extra_link}} <img loading="lazy" onerror="this.style.display='none'" alt="right-arrow" src="assets/img/homepage/right-arrow.png"
                        class="blog-arrow"></a>
                </div>
                <!-- web view -->
                <div class="row web-view cardsFlow">
                    <ng-container *ngFor="let sub_item of storyData; let i=index">
                        <div class="col-lg-4">
                            <div class="blog-card" routerLink="/stories/{{sub_item?.client_story_id?.slug}}" (click)="scrollTop">
                                <div *ngIf="sub_item?.client_story_id?.image?.id" class="card-top client-blog-card1" [ngStyle]="{'background-image': 'url(' + imgURL+sub_item?.client_story_id?.image?.id + ')'}">
                                    <div class="client-blog-logo" *ngIf="sub_item?.client_story_id?.subcontent_logo">
                                        <img loading="lazy"  src="{{imgURL}}{{sub_item?.client_story_id?.subcontent_logo?.id}}" alt="{{sub_item.client_story_id?.subcontent_logo_alt_text}}">
                                    </div>
                                </div>
                                <div class="card-body">
                                    <h3 class="card-title" *ngIf="sub_item?.client_story_id?.name">{{sub_item?.client_story_id?.name}}</h3>
                                    <p class="card-text" *ngIf="sub_item?.client_story_id?.sub_head_description">{{sub_item?.client_story_id?.sub_head_description}}</p>
                                    <span class="badge badge-primary mb-0" *ngFor="let tag of sub_item?.keywordsArray">{{tag}}</span>
                                    <div class="d-flex mt-1">
                                        <p class="detail">
                                            <ng-container *ngIf='sub_item?.client_story_id?.author_name'>{{sub_item?.client_story_id?.author_name}}</ng-container>  
                                            <ng-container *ngIf='sub_item?.client_story_id?.date'> | {{sub_item?.client_story_id?.date}}</ng-container>
                                        </p>
                                    </div>
                                    <a routerLink="/stories/{{sub_item?.client_story_id?.slug}}" (click)="scrollTop()" class="readMore">View Post<img onerror="this.style.display='none'" alt="right-arrow" src="../../../assets/img/right-arrow.svg"></a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
            <div class="blogs-section mobile-view" *ngFor="let item of relatedCase">

                <!-- mobile view -->
                <div class="blog-mobile">
                    <ng-container *ngFor="let sub_item of storyData; let i=index">
                        <div class="blog-card" routerLink="/stories/{{sub_item?.client_story_id?.slug}}" (click)="scrollTop">
                            <div class="card-top client-blog-card3" *ngIf="sub_item?.client_story_id?.subhead_images?.id" 
                            [ngStyle]="{'background-image': 'url(' + imgURL+sub_item?.client_story_id?.subhead_images?.id + ')'}">
                                <div class="client-blog-logo" *ngIf="sub_item?.subcontent_logo">
                                    <img loading="lazy" src="{{imgURL}}{{sub_item?.client_story_id?.subcontent_logo?.id}}" alt="{{sub_item?.client_story_id?.subcontent_logo_alt_text}}"> 
                                </div>
                            </div>
                            <div class="card-body">
                                <h3 class="card-title" *ngIf="sub_item?.client_story_id?.sub_head">{{sub_item?.client_story_id?.sub_head}}</h3>
                                <p class="card-text" *ngIf="sub_item?.client_story_id?.sub_head_description">{{sub_item?.client_story_id?.sub_head_description}}</p>
                                <span class="badge badge-primary" *ngFor="let tag of sub_item?.keywordsArray">{{tag}}</span>
                                <a routerLink="/stories/{{sub_item?.client_story_id?.slug}}" (click)="scrollTop()" class="readMore">View Post<img onerror="this.style.display='none'" alt="right-arrow" src="../../../assets/img/right-arrow.svg"></a>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
    </div>
</section>
