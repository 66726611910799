import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
declare function loadMeta(titVal:any,metaTitVal:any,metaDesVal:any ):any;
declare function pageMetaTypeURL(url: any, type: any): any;

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {

  imgURL = `${environment.apiURL}/assets/` ;
  allData:any;
  ContentSec:any;
  pageInfo:any;
  banner:any;
  subbanner:any;
  process:any;
  faq:any;
  contact:any;
  formSubmitted = false;
  form2clicked = false;
  titleValue:any='';
  metaTitVal:any='';
  metaDesVal:any='';
  pageUrl:any='';
  pageType:any='';

  constructor(private data:DataService, private router: Router,private formBuilder: FormBuilder, private httpClient: HttpClient, private transferState: TransferStateService) { }

  checkoutForm = this.formBuilder.group({
    uName: ['',[Validators.required]],
    uEmail: ['', [
                    Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
                  ],
            ],
    uCompany: ['',[Validators.required]],
    uRole: ['',[Validators.required]],
    uMessage: ['',[Validators.required]],
    uCheck: ''         
  });
  
  headers = new HttpHeaders({'Content-Type': 'application/json', 
  'Access-Control-Allow-Origin': ['*'], 
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, PATCH' });
  
  public sendEmail(e: any) {
    this.form2clicked = true;
      if(this.checkoutForm.value.uEmail == '' || this.checkoutForm.controls.uEmail.errors){
        return;
      }
      else if(this.checkoutForm.value.uName == '' || this.checkoutForm.controls.uName.errors){
        return;
      }
      else if(this.checkoutForm.value.uCompany == '' || this.checkoutForm.controls.uCompany.errors){
        return;
      }
      else if(this.checkoutForm.value.uRole == '' || this.checkoutForm.controls.uRole.errors){
        return;
      }
      else if(this.checkoutForm.value.uMessage == '' || this.checkoutForm.controls.uMessage.errors){
        return;
      }
    //e.preventDefault();
    emailjs.sendForm('service_q72nzhk', 'template_aenhbee', e , 'user_dLZ4cHxwfhEFbBw8luW0h')
      .then((result: EmailJSResponseStatus) => {
        this.formSubmitted = true;
          window.open("/thank-you", "_self");
      }, (error) => {
        // console.log(error.text);
      });
  }
  
  checkInput(){
    let value = false;
    Object.keys(this.checkoutForm.controls).forEach((key:any) => {
      const controlErrors: any = this.checkoutForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          if(keyError=="required"){
            value=true;
          }
        });
      }
    });
    return value;
  }
  
  
  async onClickSubmit(info:any){
    //this.headers = this.headers.append('Authorization','VALUE');
    return new Promise(resolve => {
      this.httpClient.post(`${environment.apiURL}/items/contact_detail/?access_token=${environment.token}`,
                            {
                              name: info.uName,
                              email: info.uEmail,
                              company: info.uCompany,
                              role: info.uRole,
                              message: info.uMessage,
                              subscriber: info.uCheck,
                            },
                            {headers:this.headers}
                          )
                        .subscribe((data => { 
                          // console.log(data); 
                        }))
      // this.httpClient.post(`${environment.apiURL}/items/contact_detail/?access_token=${environment.token}`, {name: this.checkoutForm.value.uName},{headers:this.headers}).subscribe(data => {
      // });
  
      // this.response = await axios.post(`${environment.apiURL}/items/contact_detail/?access_token=${environment.token}`,
      // {name: "info.uName"} );
      
  }
  )}

  ngOnInit(): void {
    let careerPageData = this.transferState.useScullyTransferState('careerTS', this.data.getPageData(this.router?.url?.split('?')[0]))
    careerPageData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections
        this.pageInfo = this.allData
        // console.log(this.pageInfo)
        this.titleValue = this.pageInfo?.title
        this.metaTitVal = this.pageInfo?.meta_title_content
        this.metaDesVal = this.pageInfo?.meta_description_content
        loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);

        this.pageUrl = window?.location?.href;
        this.pageType = this.pageInfo?.metaOgType;
        pageMetaTypeURL(this.pageUrl, this.pageType);
        
        this.banner = Array.of(this.ContentSec?.[0]?.content_section_id) //banner section detail
        this.subbanner = Array.of(this.ContentSec?.[1]?.content_section_id)
        this.process = Array.of(this.ContentSec?.[2]?.content_section_id) //banner section detail
        this.faq = Array.of(this.ContentSec?.[3]?.content_section_id)
        this.contact = Array.of(this.allData?.content_sections?.[5]?.content_section_id)
      } 
    });
  }

  ngOnDestroy(){
    let isMetaUrlAvailable = document.getElementById("metaURL");
    let isMetaTypeAvailable = document.getElementById("metaTYPE");
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }
}
