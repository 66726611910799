<app-header></app-header>
<ng-container *ngFor="let item of banner">
    <div class="audit-hero">
        <div class="container">
            <h1 *ngIf="item.heading">{{item.heading}}</h1>
            <p *ngIf="item.description">{{item.description}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngFor="let item of subbanner">
    <div class="audit-cards-section">
        <div class="audit-cards">
            <div class="container">
                <div class="row webCards">
                    <div class="col-lg-3" *ngFor="let sub of item?.subcontent_sections">
                        <div class="card">
                            <h4 *ngIf="sub.sub_head">{{sub.sub_head}}</h4>
                            <p *ngIf="sub.sub_head_description">{{sub.sub_head_description}}</p>
                        </div>
                    </div>
                </div>
                <!-- for mobile -->
                <div class="mobCards">
                    <div *ngFor="let sub of item?.subcontent_sections">
                        <div class="card">
                            <h4 *ngIf="sub.sub_head">{{sub.sub_head}}</h4>
                            <p *ngIf="sub.sub_head_description">{{sub.sub_head_description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngFor="let item of process">
    <div class="process-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="content">
                        <div>
                            <h2 *ngIf="item.name">{{item.name}}</h2>
                            <img loading="lazy" onerror="this.style.display='none'" alt="border" class="divider" src='../../../assets/img/border.svg'>
                        </div>
                        <p *ngIf="item.description">{{item.description}}</p>
                        <img loading="lazy" *ngIf="item.image" src="{{imgURL}}{{item.image?.filename_disk}}" alt="{{item.image_alt_text}}" class="mobNone">
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="detailCards">
                        <div class="accordion" id="accordionExample">
                            <ng-container *ngFor="let sub of item?.subcontent_sections; let i=index;">
                                <div class="accordion-item" *ngIf='i===0'>
                                    <h2 class="accordion-header" id="headingOne" *ngIf="sub.sub_head">
                                        <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapse0"
                                             aria-expanded="true" aria-controls="collapseOne" *ngIf="sub.sub_head">
                                            {{i+1}}. {{sub.sub_head}}
                                        </button>
                                    </h2>
                                    <div id="collapse0" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body" *ngIf="sub.sub_head_description">
                                            <p>{{sub.sub_head_description}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item" *ngIf='i===1'>
                                    <h2 class="accordion-header" id="headingOne" *ngIf="sub.sub_head">
                                        <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapse1"
                                            aria-expanded="true" aria-controls="collapseOne" *ngIf="sub.sub_head">
                                            {{i+1}}. {{sub.sub_head}}
                                        </button>
                                    </h2>
                                    <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body" *ngIf="sub.sub_head_description">
                                            <p>{{sub.sub_head_description}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item" *ngIf='i===2'>
                                    <h2 class="accordion-header" id="headingOne" *ngIf="sub.sub_head">
                                        <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapse2"
                                            aria-expanded="true" aria-controls="collapseOne" *ngIf="sub.sub_head">
                                            {{i+1}}. {{sub.sub_head}}
                                        </button>
                                    </h2>
                                    <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body" *ngIf="sub.sub_head_description">
                                            <p>{{sub.sub_head_description}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item" *ngIf='i===3'>
                                    <h2 class="accordion-header" id="headingOne" *ngIf="sub.sub_head">
                                        <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapse3"
                                            aria-expanded="true" aria-controls="collapseOne" *ngIf="sub.sub_head">
                                            {{i+1}}. {{sub.sub_head}}
                                        </button>
                                    </h2>
                                    <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body" *ngIf="sub.sub_head_description">
                                            <p>{{sub.sub_head_description}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item" *ngIf='i===4'>
                                    <h2 class="accordion-header" id="headingOne" *ngIf="sub.sub_head">
                                        <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapse4"
                                            aria-expanded="true" aria-controls="collapseOne" *ngIf="sub.sub_head">
                                            {{i+1}}. {{sub.sub_head}}
                                        </button>
                                    </h2>
                                    <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body" *ngIf="sub.sub_head_description">
                                            <p>{{sub.sub_head_description}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item" *ngIf='i===5'>
                                    <h2 class="accordion-header" id="headingOne" *ngIf="sub.sub_head">
                                        <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapse5"
                                            aria-expanded="true" aria-controls="collapseOne" *ngIf="sub.sub_head">
                                            {{i+1}}. {{sub.sub_head}}
                                        </button>
                                    </h2>
                                    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body" *ngIf="sub.sub_head_description">
                                            <p>{{sub.sub_head_description}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item" *ngIf='i===6'>
                                    <h2 class="accordion-header" id="headingOne" *ngIf="sub.sub_head">
                                        <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapse6"
                                            aria-expanded="true" aria-controls="collapseOne" *ngIf="sub.sub_head">
                                            {{i+1}}. {{sub.sub_head}}
                                        </button>
                                    </h2>
                                    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body" *ngIf="sub.sub_head_description">
                                            <p>{{sub.sub_head_description}}</p>
                                        </div>
                                    </div>
                                </div> 
                            </ng-container>                               
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<app-testimonial></app-testimonial>

<ng-container *ngFor="let item of faq">
    <div class="faq-section">
        <div class="container">
            <h2 *ngIf="item.name">{{item.name}}</h2>
            <div class="faq-cards">
                <div class="accordion" id="accordionExample">
                    <ng-container *ngFor="let sub of item?.subcontent_sections; let i=index">
                        <div class="accordion-item" *ngIf='i===0'>
                            <h2 class="accordion-header" id="headingOne" *ngIf="sub.sub_head">
                                <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapse-0"
                                    aria-expanded="true" aria-controls="collapseOne" *ngIf="sub.sub_head">
                                    {{sub.sub_head}}
                                </button>
                            </h2>
                            <div id="collapse-0" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body" *ngIf="sub.sub_head_description">
                                    <p>{{sub.sub_head_description}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" *ngIf='i===1'>
                            <h2 class="accordion-header" id="headingOne" *ngIf="sub.sub_head">
                                <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapse-1"
                                    aria-expanded="true" aria-controls="collapseOne" *ngIf="sub.sub_head">
                                    {{sub.sub_head}}
                                </button>
                            </h2>
                            <div id="collapse-1" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body" *ngIf="sub.sub_head_description">
                                    <p>{{sub.sub_head_description}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" *ngIf='i===2'>
                            <h2 class="accordion-header" id="headingOne" *ngIf="sub.sub_head">
                                <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapse-2"
                                    aria-expanded="true" aria-controls="collapseOne" *ngIf="sub.sub_head">
                                    {{sub.sub_head}}
                                </button>
                            </h2>
                            <div id="collapse-2" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body" *ngIf="sub.sub_head_description">
                                    <p>{{sub.sub_head_description}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" *ngIf='i===3'>
                            <h2 class="accordion-header" id="headingOne" *ngIf="sub.sub_head">
                                <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapse-3"
                                    aria-expanded="true" aria-controls="collapseOne" *ngIf="sub.sub_head">
                                    {{sub.sub_head}}
                                </button>
                            </h2>
                            <div id="collapse-3" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body" *ngIf="sub.sub_head_description">
                                    <p>{{sub.sub_head_description}}</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<app-navigation-sec></app-navigation-sec>

<div class="extra-pad"></div>

<div class="pb-0" *ngFor="let item of contact">
    <div class="contact-alt">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="content">
                        <h6 *ngIf="item.name">{{item.name}}</h6>
                        <h3 *ngIf="item.heading">{{item.heading}}</h3>
                    </div>
                </div>
                <div class="col-lg-7 bg-show" >
                    <form [formGroup]="checkoutForm" #subForm (ngSubmit) = "onClickSubmit(checkoutForm.value);sendEmail(subForm)">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group floating">
                                    <input type="text" class="form-control floating" placeholder=" " name="uName" id="name" formControlName="uName" [ngClass]="{'errorBorder' : form2clicked && (checkoutForm.controls.uName.errors?.required)}" autocomplete="off">
                                    <label for="name" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uName.errors?.required)}">Name</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group floating">
                                    <div>
                                        <p class="error-text" *ngIf="form2clicked && (checkoutForm.controls.uEmail.errors?.pattern)">Incorrect Email. Please try again. </p>
                                    </div>
                                    <input type="text" class="form-control floating" placeholder=" " id="email" name="uEmail" formControlName="uEmail" [ngClass]="{'errorBorder' : form2clicked && (checkoutForm.controls.uEmail.errors?.pattern || checkoutForm.controls.uEmail.errors?.required)}" autocomplete="off">
                                    <label for="email" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uEmail.errors?.pattern || checkoutForm.controls.uEmail.errors?.required)}">Email</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group floating">
                                    <input type="text" class="form-control floating" placeholder=" " id="company" name="uCompany" formControlName="uCompany" [ngClass]="{'errorBorder' : form2clicked && (checkoutForm.controls.uCompany.errors?.required)}" autocomplete="off">
                                    <label for="company" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uCompany.errors?.required)}">Company</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group floating">
                                    <input type="text" class="form-control floating" placeholder=" " id="role" name="uRole" formControlName="uRole" [ngClass]="{'errorBorder' : form2clicked && (checkoutForm.controls.uRole.errors?.required)}" autocomplete="off">
                                    <label for="role" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uRole.errors?.required)}">Role</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group message floating">
                                    <textarea class="form-control floating" rows="5" placeholder=" " id="message" name="uMessage" formControlName="uMessage" [ngClass]="{'errorMsgBorder' : form2clicked && (checkoutForm.controls.uMessage.errors?.required)}"></textarea>
                                    <label for="message" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uMessage.errors?.required)}">Message</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label" for="vehicle1">Subscribe to Brooklyn Data Co. Newsletter</label>
                            <input type="checkbox" class="form-check-input" id="vehicle1" name="uCheck"  formControlName="uCheck" value="Bike">
                        </div>
                        <div>
                            <p class="error-text2" *ngIf="form2clicked && (checkoutForm.controls.uEmail.errors?.required || checkoutForm.controls.uName.errors?.required || checkoutForm.controls.uCompany.errors?.required)">All fields must be completed. </p>
                        </div>
                        <button aria-label="button" type = "submit"  class="btn btns" *ngIf="item.button_label">{{item.button_label}}</button>
                    </form>
                </div>
            </div>
        </div>
        <div class="contact-img"></div>
    </div>
</div>