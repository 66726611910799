<app-header-two></app-header-two>

<!-- content starts here  -->

<div class="not-found" *ngIf="page">
    <div class="container">
        <ng-container *ngFor="let item of page">
            <h2 *ngIf="item?.heading">{{item?.heading}}</h2>
            <h6 *ngIf="item?.name">{{item?.name}}</h6>
            <p *ngIf="item?.description">{{item?.description}}</p>
            <a href="{{item?.button_link}}" class="btn btns" *ngIf="item?.button_label">{{item?.button_label}}</a>
        </ng-container>
    </div>
</div>