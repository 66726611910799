import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TransferStateService } from '@scullyio/ng-lib';
declare function loadMeta(titVal:any,metaTitVal:any,metaDesVal:any ):any;
declare function pageMetaTypeURL(url: any, type: any): any;

@Component({
  selector: 'app-tools-internal',
  templateUrl: './tools-internal.component.html',
  styleUrls: ['./tools-internal.component.scss']
})
export class ToolsInternalComponent implements OnInit {

  imgURL = `${environment.apiURL}/assets/`
  allData:any;
  ContentSec:any;
  pageInfo:any;
  banner:any;
  testSec:any;
  partner:any;
  relatedResources:any;
  relatedCase:any;
  contact: any;
  caseKeywordsArray:any;
  titleValue:any='';
  metaTitVal:any='';
  metaDesVal:any='';
  pageUrl:any='';
  pageType:any='';

  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
  }

  ngOnInit() {
    let toolsInternalData = this.transferState.useScullyTransferState('toolsInternalTS', this.data.getPageData(this.router?.url?.split('?')[0]));
      toolsInternalData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections
        this.pageInfo = this.allData
        this.titleValue = this.pageInfo?.title
        this.metaTitVal = this.pageInfo?.meta_title_content
        this.metaDesVal = this.pageInfo?.meta_description_content
        loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);
        
        this.pageUrl = window?.location?.href;
        this.pageType = this.pageInfo?.metaOgType;
        pageMetaTypeURL(this.pageUrl, this.pageType);

        this.banner = Array.of(this.ContentSec?.[0]?.content_section_id)
        console.log(this.banner) //banner section detail
        this.testSec = this.pageInfo?.testimonial?.testimonials
        this.partner = Array.of(this.ContentSec?.[1]?.content_section_id) //partner section detail
        this.relatedResources = Array.of(this.ContentSec?.[2]?.content_section_id) //relatedResources section detail
        this.relatedCase = Array.of(this.ContentSec?.[3]?.content_section_id) //relatedCase section detail
        this.contact = Array.of(this.ContentSec?.[4]?.content_section_id) //contact section detail
        for(let i=0; i<this.relatedResources?.[0]?.subcontent_sections?.length; i++){
          this.relatedResources[0].subcontent_sections[i].keywordsArray= this.relatedResources?.[0]?.subcontent_sections?.[i]?.keywords?.split(', ')
        }
        for(let i=0; i<this.relatedCase?.[0]?.subcontent_sections?.length; i++){
          this.relatedCase[0].subcontent_sections[i].caseKeywordsArray= this.relatedCase?.[0]?.subcontent_sections?.[i]?.keywords?.split(', ')
        }
      }
    });
  }

  ngOnDestroy(){
    let isMetaUrlAvailable = document.getElementById("metaURL");
    let isMetaTypeAvailable = document.getElementById("metaTYPE");
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }

  // for owl carousel
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    freeDrag:true,
    dotsEach: false,
    dots: false,
    navSpeed: 600,
    navText: ['<img loading="lazy"  src="assets/img/left-arrow.svg">','<img loading="lazy"  src="assets/img/right-arrow.svg">'],
    responsive: {
      0: {
        items: 1.3 
      },
      640: {
        items: 2
      },
      1000: {
        items: 2
      },
      1200: {
        items: 2
      }
    },
    nav: true
  }
}
