import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Renderer2, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { isScullyRunning } from "@scullyio/ng-lib";

@Component({
  selector: 'app-gtm',
  templateUrl: './gtm.component.html',
  styleUrls: ['./gtm.component.scss']
})
export class GtmComponent implements OnInit {

  constructor(
    private router: Router,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) { }

  ngOnInit(): void {

    if (isScullyRunning()) {
      return;
    }
    let script = this._renderer2.createElement("script");
    script.type = `text/javascript`;
    script.defer = "true";
    script.text = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MJXN73P');
    `;
        this._renderer2.appendChild(this._document.head, script);
  }

}
