<app-header-two></app-header-two>

<!-- Hero-Section-blogs starts -->

<div class="hero-blogs-section">
    <ng-container *ngIf="banner">
        <div class="container" *ngFor="let item of banner">
            <h1 *ngIf="item.heading">{{item.heading}}</h1>
            <p *ngIf="item.description">{{item.description}}</p>
        </div>
    </ng-container>
</div>

<!-- Hero-section-blogs ends  -->

<!-- dropdowns for Blogs starts -->

<div class="blogs-selection">

    <!-- dropdown 1st -->

    <div class="dropdown">
        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{(filter.category=='')?'Category':filter.category}}
            <img onerror="this.style.display='none'" src="/assets/img/arrow-down.svg" alt="arrow-down">
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="clearCategory()" href="javascript:;">All</a>
            <ng-container *ngFor="let item of dropDownCategory; let i=index">
                <a *ngIf="i>=0 && item.name" class="dropdown-item" (click)="setFilter('category',item.name)"
                    href="javascript:;">{{item.name}}</a>
            </ng-container>
        </div>
    </div>
    <!-- dropdown 2nd -->

    <div class="dropdown">
        <button class="btn pad dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{(filter.tools=='')?'Tool':filter.tools}}
            <img onerror="this.style.display='none'" src="/assets/img/arrow-down.svg" alt="arrow-down">
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="clearTool()" href="javascript:;">All</a>
            <ng-container *ngFor="let item of dropDownTool; let i=index">
                <a *ngIf="i>=0 && item.name" class="dropdown-item" (click)="setFilter('tools',item.name)"
                    href="javascript:;">{{item.name}}</a>
            </ng-container>
        </div>
    </div>

</div>

<!-- dropdowns for Blogs ends -->

<!-- Blogs cards starts -->


<div class="blogs">
    <div class="container">
        <div class="blogs-section">
            <div class="row">
                <ng-container *ngFor="let sub_item of relatedShow | paginate: { id: 'listing_pagination',itemsPerPage: 15,
                                        currentPage: page, totalItems: totalRec }; let i=index">
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="blog-card" (click)="scrollTop" routerLink="{{sub_item?.slug}}">
                            <!-- change {{sub_item?.class}} to "card-top" in below class -->
                            <div class="card-top">
                                <img Loading="lazy" *ngIf="sub_item?.card_image" src="{{imgURL}}{{sub_item?.card_image?.id}}" alt="{{sub_item.subhead_image_alt_text}}"
                                    class="top-img">
                            </div>
                            <div class="card-body">
                                <h3 class="card-title" *ngIf="sub_item?.name">{{sub_item?.name}}</h3>
                                <!-- <p class="card-text" *ngIf="sub_item?.sub_head_description">{{sub_item?.sub_head_description}}</p> -->
                                <span class="badge badge-primary" *ngFor="let tag of sub_item?.keywordsArray">{{tag}}</span>
                                <div class="d-flex mt-1">
                                    <p class="detail"> 
                                        <ng-container *ngIf='sub_item?.author_name' >{{sub_item?.author_name}}</ng-container>  
                                        <ng-container *ngIf='sub_item?.date'> | {{sub_item?.date}}</ng-container>
                                    </p>
                                </div>
                                <a routerLink="/blog/{{sub_item?.slug}}" (click)="scrollTop()" class="readMore">View Post<img onerror="this.style.display='none'" src="../../../assets/img/right-arrow.svg" alt="right-arrow"></a>
                                <!-- <a routerLink="/blogs/{{sub_item?.slug}}" (click)="scrollTop()" class="learnMore">View Post<img onerror="this.style.display='none'" src="../../../assets/img/right-arrow.svg" alt="right-arrow"></a> -->
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>


<!-- Blogs cards ends -->

<!-- Pagination Start -->
<div class="paginationControls">
    
        <pagination-controls (click)="scrollTop()" id="listing_pagination" (pageChange)="page = $event" previousLabel="Prev"
            nextLabel="Next">
        </pagination-controls>
    
</div>
<!-- Pagination end -->
