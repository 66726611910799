<app-header-two></app-header-two>

<div class="hero-section-case-studies">
    <div class="container">
        <div class="row">
            <div class="col-xl-7 col-lg-6 col-sm-6 col-12 order-lg-1 order-sm-1 order-2">
                <div class="hero-img"  *ngFor ="let item of allDataContent">
                    <img loading="lazy" *ngIf="item?.image" src="{{imgURL}}{{item?.image?.id}}" alt="{{item.image_alt_text}}">
                </div>
                
                    <div class="hero-content web-none ipad-none" *ngFor ="let subitem of allDataContent">
                        <div class="author">
                            <img *ngIf="subitem?.Author_Image" loading="lazy" src="{{imgURL}}{{subitem?.Author_Image?.id}}" alt="{{subitem?.subcontent_logo_alt_text}}">
                            <div class="author-details">
                                <h4 *ngIf="subitem?.author_name"> By {{subitem?.author_name}}</h4>
                                <p>{{subitem?.date}} <ng-container *ngIf="subitem?.average_reading_time">|</ng-container> {{subitem?.average_reading_time}}</p>
                            </div>
                            
                        </div>
                        <span class="badge badge-primary " *ngFor="let tag of subitem?.keywordsArray">{{tag}}</span>
                    </div>
                
                
            </div>
            <div class="col-xl-5 col-lg-6 col-sm-6 col-12 order-lg-2 order-sm-2 order-1">
                <div class="hero-content" *ngFor ="let item of allDataContent">
                    <img *ngIf="item?.logo" loading="lazy"  src="{{imgURL}}{{item?.logo?.id}}" alt="{{item?.head_icon_alt_text}}" class="partners">
                    <h3 *ngIf="item?.name">{{item?.name}}</h3>
                    <div >
                        <div class="author mobile-none ipad-show" *ngFor ="let subitem of allDataContent">
                            <img *ngIf="subitem?.Author_Image" loading="lazy" src="{{imgURL}}{{subitem?.Author_Image?.id}}" alt="{{subitem?.subcontent_logo_alt_text}}">
                            <div class="author-details">
                                <h4 *ngIf="subitem?.author_name"> By {{subitem?.author_name}}</h4>
                                <p>{{subitem?.date}} <ng-container *ngIf="subitem?.average_reading_time">|</ng-container> {{subitem?.avg_time}}</p>
                            </div>
                        </div>
                    </div>
                    <span class="badge badge-primary ipad-show" *ngFor="let tag of item?.keywordsArray">{{tag}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-1 mobile-none">
                <div class="social-links">
                    <span>Share</span>
                    <img loading="lazy" (click)="share('mail')" onerror="this.style.display='none'" src="assets/img/case-study/mail.svg" alt="mail">
                    <img loading="lazy" (click)="share('linkedin')" onerror="this.style.display='none'" src="assets/img/case-study/linkedin.svg" alt="linkedin">
                    <img loading="lazy" (click)="share('fb')" onerror="this.style.display='none'" src="assets/img/case-study/fb.svg" alt="facebook">
                </div>
            </div>
            <div class="col-lg-10 col-12">
                <div class="section-content" *ngFor ="let item of allDataContent">
                    <div class="sectionWidth">
                        <ng-container > 
                            <div [innerHtml]= "item?.text"></div>
                        </ng-container>

                        <hr class="mobile-none">
                        <div *ngFor ="let subitem of allDataContent?.[0]?.content_sections; let i = index">
                            <ng-container *ngIf="subitem?.content_section_id?.name != 'WORK WITH US'">
                                <h4 *ngIf="subitem?.content_section_id?.name">{{subitem?.content_section_id?.name}}</h4>
                                <div [innerHtml]= "subitem?.content_text" >
                                </div>
                                <ng-container *ngFor ="let sub of subitem?.content_section_id?.subcontent_sections; let i = index">
                                    <h4 *ngIf="sub?.sub_head">{{sub?.sub_head}}</h4>
                                    <div [innerHtml]= "sub?.subcontent_text" >
                                    </div>
                                    <ng-container *ngFor ="let sub_test of sub?.sub_subcontent_sec_part; let i = index">
                                        <h4 *ngIf="sub_test?.name">{{sub_test?.name}}</h4>
                                        <div [innerHtml]= "sub_test?.sub_subcontent_text" >
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                        
                        <ng-container *ngFor ="let subitem of allDataContent?.[0]?.content_sections; let i = index">
                            <div class="contact" *ngIf="subitem?.content_section_id?.name == 'WORK WITH US'">
                                <div class="row" >
                                        <div class="col-lg-8">
                                            <h3 *ngIf="subitem?.content_section_id?.heading">{{subitem?.content_section_id?.heading}}</h3>
                                        </div>
                                        <div class="col-lg-4">
                                            <a class="btn btns ml-lg-5 mt-4" (click)="goToPage('contact')">{{subitem?.content_section_id?.button_label}}</a>
                                        </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="social">
                            <span>Share</span>
                            <div class="links">
                                <img loading="lazy" (click)="share('mail')" onerror="this.style.display='none'" src="assets/img/case-study/mail.svg" alt="mail">
                                <img loading="lazy" (click)="share('linkedin')" onerror="this.style.display='none'" src="assets/img/case-study/linkedin.svg" alt="linkedin">
                                <img loading="lazy" (click)="share('fb')" onerror="this.style.display='none'" src="assets/img/case-study/fb.svg" alt="facebook">
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="col-lg-1 mobile-none">
                
            </div>
        </div>
    </div>
</section>

<!-- Blogs cards starts here  -->

<app-related-case-study></app-related-case-study>

<div class="extra-pad-down"></div>


<!-- Blogs cards ends here  -->