import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
declare var $:any;

@Component({
  selector: 'app-cards-slider',
  templateUrl: './cards-slider.component.html',
  styleUrls: ['./cards-slider.component.scss']
})
export class CardsSliderComponent implements OnInit {

  imgURL = `${environment.apiURL}/assets/`
  allData:any;
  stack:any;
  ContentSec:any;
  pageInfo:any;
  cardSlider:any
  customOptions: OwlOptions = {};

  constructor(
    private data:DataService, private router: Router, private transferState: TransferStateService
  ) { }

  // goToPage(pageName:string){
  //   this.router.navigate([`${pageName}`]);
  //   window.scroll(0,0); // to scroll to page top when button clicked
  // }

  ngOnInit(): void {

    let awardsData = this.transferState.useScullyTransferState('relatedData', this.data.getPageData(this.router?.url?.split('?')[0]));
    awardsData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections;
        for(let i=0; i<=this.ContentSec.length ; i++){
          if(this.ContentSec?.[i]?.content_section_id?.common_name === "stack experts" ){
            this.cardSlider = Array.of(this.ContentSec?.[i]?.content_section_id);
          }
        }
      }        
    })

    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      freeDrag:true,
      dots: true,
      navSpeed: 600,
      responsive: {
        0: {
          items: 1 
        },
        600: {
          items: 2
        },
        760: {
          items: 3
        },
        1000: {
          items: 4
        }
      },
      nav: true,
      lazyLoad: true, 
      responsiveRefreshRate: 5
    }
  }

}
