import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from './../../data.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TransferStateService } from '@scullyio/ng-lib';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isScullyRunning } from '@scullyio/ng-lib';
declare function pageMetaImage(imgLink: any): any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  imgURL = `${environment?.apiURL}/assets/`;
  datas: any;
  allData: any;
  navigation: any;
  pEmail: any;
  templateParams: any;
  form1Clicked = false;
  formSubmitted = false;
  form2clicked = false;
  imgLink: any = '';
  img: any = '';
  UpdateYear = new Date().getFullYear();

  constructor(
    private sdata: DataService,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private transferState: TransferStateService,
    private router: Router,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  checkoutForm = this.formBuilder.group({
    uEmail: [
      '',
      [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
      ],
    ],
  });

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': ['*'],
    // 'Access-Control-Allow-Credentials': 'true',
    // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, PATCH'
  });

  async emailSend(details: any) {
    return this.httpClient
      .post(
        `${environment.apiURL}/send-email/?access_token=${environment.token}`,
        {
          email: details.uEmail,
          subscriber: true,
        },
        { headers: this.headers }
      )
      .subscribe((data) => {
        // console.log(data);
      });
  }

  async onClickSubmit(info: any) {
    this.form2clicked = true;
    if (
      this.checkoutForm.value.uEmail == '' ||
      this.checkoutForm.controls.uEmail.errors
    ) {
      return;
    }
    this.httpClient
      .post(
        `${environment.apiURL}/items/contact_detail/?access_token=${environment.token}`,
        {
          email: info.uEmail,
          subscriber: true,
        },
        { headers: this.headers }
      )
      .subscribe((data) => {
        // console.log(data);
        // this.emailSend(this.checkoutForm.value).then(()=> {
        this.formSubmitted = true;
        // window.open("/thank-you", "_self");
        // });
      });
  }

  ngOnInit() {
    let footerData = this.transferState.useScullyTransferState(
      'footerTS',
      this.sdata.getHeader()
    );
    footerData.subscribe((result) => {
      this.datas = result;
      this.allData = Array.of(this.datas?.data?.[1]);
      //console.log(this.allData)
      this.navigation = this.datas?.data?.[1]?.navigations;
      // console.log(this.navigation)
      this.img = this.datas?.data?.[1]?.image?.filename_disk;
      this.imgLink = `${this.imgURL}${this.img}`;
      //console.log(this.imgLink)
      pageMetaImage(this.imgLink);
    });
  }
}
