<app-header></app-header>


<!-- Hero-Section-solution starts -->

<div class="hero-section-approach" *ngIf="banner">
    <div class="container">
        <div *ngFor="let item of banner">
            <h1 *ngIf="item.heading" >{{item.heading}}</h1>
            <div class="text-center" *ngIf="item.description">
                <p>{{item.description}}</p>
            </div>
            <div class="text-center">
                <button class="btn btns mt-lg-4 hero--sec--btn"  *ngIf="item.button_label" >{{item.button_label}}</button>
            </div>
        </div>
    </div>
</div>

<!-- Section 2 Start -->

<!-- Desktop View Start -->
<div style="position: relative;">
    <div class="hero-common-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div>
                        <div class="sub-content" *ngFor="let item of people">
                            <img loading="lazy" alt="icon" onerror="this.style.display='none'" class="content-icon-left" src='../../../assets/img/approach/Icon1.svg'>
                            <h2 *ngIf="item.heading">{{item.heading}}</h2>
                            <div class="mob-divider">
                                <img loading="lazy" alt="border" class="divider" onerror="this.style.display='none'" src='../../../assets/img/border.svg'>
                            </div>
                            <div class="content-desciption">
                                <p *ngIf="item.description">{{item.description}}</p>
                                <div class="list">
                                    <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex">
                                        <div>
                                            <span class="list__icon"></span>
                                        </div>
                                        <div>
                                            <span class="list__item">{{subitem.sub_head}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="learn__more" *ngIf="item.extra_link_text">
                                    <a class="link" href="{{item.extra_link}}">{{item.extra_link_text}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12" *ngFor="let item of people">
                    <img class="right__sec__img" *ngIf="item.image" loading="lazy" src="{{imgURL}}{{item.image?.id}}" alt="{{item.image_alt_text}}">
                </div>

            </div>
        </div>
    </div>

    <div class="dashed dashed-web-left">
        <img src="assets/img/dash-web-left.png" class="dashed-img">
    </div>
    <div class="mob-dashed">
        <img src="assets/img/approach/mob-dashed.png" class="dashed-img">
    </div>

    <div class="hero-common-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12 order-lg-1 order-sm-1 order-2" *ngFor="let item of process">
                    <img class="left__sec__img"  *ngIf="item.image"loading="lazy" src="{{imgURL}}{{item.image?.id}}" alt="{{item.image_alt_text}}">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12  order-lg-2 order-sm-2 order-1">
                    <div>
                        <div class="sub-content right" *ngFor="let item of process">
                            <img loading="lazy" alt="icon" onerror="this.style.display='none'" class="content-icon-right" src='../../../assets/img/approach/Icon2.svg'>
                            <h2 *ngIf="item.heading">{{item.heading}}</h2>
                            <div class="mob-divider">
                                <img loading="lazy" alt="border" onerror="this.style.display='none'" class="divider" src='../../../assets/img/border.svg'>
                            </div>
                            <div class="content-desciption">
                                <p *ngIf="item.description">{{item.description}}</p>
                                <div class="list "  >
                                    <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex">
                                        <div>
                                            <span class="list__icon"></span>
                                        </div>
                                        <div *ngIf="subitem.sub_head">
                                            <span class="list__item">{{subitem.sub_head}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="learn__more" *ngIf="item.extra_link_text">
                                    <a class="link" href="{{item.extra_link}}">{{item.extra_link_text}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dashed dashed-web-right">
        <img onerror="this.style.display='none'" src="assets/img/dash-web-right.png" class="dashed-img">
    </div>
    <div class="mob-dashed">
        <img onerror="this.style.display='none'" src="assets/img/approach/mob-dashed.png" class="dashed-img">
    </div>


    <div class="hero-common-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="sub-content" *ngFor="let item of technology">
                        <img loading="lazy" class="content-icon-left" onerror="this.style.display='none'" alt="icon" src='../../../assets/img/approach/Icon3.svg'>
                        <h2 *ngIf="item.heading">{{item.heading}}</h2>
                        <div class="mob-divider">
                            <img loading="lazy" class="divider" alt="border" onerror="this.style.display='none'" src='../../../assets/img/border.svg'>
                        </div>
                        <div class="content-desciption">
                            <p *ngIf="item.description">{{item.description}}</p>
                            <div class="list">
                                <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex">
                                    <div >
                                        <span class="list__icon"></span>
                                    </div>
                                    <div *ngIf="subitem.sub_head">
                                        <span class="list__item">{{subitem.sub_head}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="learn__more" *ngIf="item.extra_link_text">
                                <a class="link" href="{{item.extra_link}}">{{item.extra_link_text}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12" *ngFor="let item of technology">
                    <img class="right__sec__img" *ngIf="item.image" loading="lazy" src="{{imgURL}}{{item.image?.id}}" alt="{{item.image_alt_text}}">
                </div>
            </div>
        </div>    
    </div>   
</div>

<!-- Desktop View Ends -->

<!-- Section 2 Start -->

<div style="padding-top: 20px"></div>

<!-- Testimonial starts here  -->

<app-testimonial></app-testimonial>

<div style="padding-block: 65px;">

</div>


<app-contact-alt></app-contact-alt>