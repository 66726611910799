import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class DataService {
  filter='Draft,Published';

  constructor(private http :HttpClient) {
    // console.log('------------------' +environment.production);
    if(environment.production) {
      this.filter = 'Published';
    }
  }

  getPageData(url:string){
    let pageurl;
    // if(environment.production){
    //   pageurl = `${environment.apiURL}/items/page?fields=*,testimonial.*,testimonial.testimonials.*,testimonial.testimonials.company_logo.*,testimonial.testimonials.user_icon.*,content_sections.*,content_sections.content_section_id.*,content_sections.content_section_id.head_icon.*,content_sections.content_section_id.bg_img.*,content_sections.content_section_id.image.*,content_sections.content_section_id.subcontent_sections.*,content_sections.content_section_id.subcontent_sections.subcontent_logo.*,content_sections.content_section_id.subcontent_sections.subhead_images.directus_files_id.*,content_sections.content_section_id.subcontent_sections.subhead_images.*,content_sections.content_section_id.subcontent_sections.sub_subcontent_sec_part.*,content_sections.content_section_id.subcontent_sections.sub_subcontent_sec_part.image.*&filter[slug][_eq]=${url}&access_token=${environment.token}&filter[status][_eq]=published`;
    //   return this.http.get(pageurl)
    // }else{
    //   console.log(url);
    //   pageurl= `${environment.apiURL}/items/page?fields=*,testimonial.*,testimonial.testimonials.*,testimonial.testimonials.company_logo.*,testimonial.testimonials.user_icon.*,content_sections.*,content_sections.content_section_id.*,content_sections.content_section_id.head_icon.*,content_sections.content_section_id.bg_img.*,content_sections.content_section_id.image.*,content_sections.content_section_id.subcontent_sections.*,content_sections.content_section_id.subcontent_sections.subcontent_logo.*,content_sections.content_section_id.subcontent_sections.subhead_images.directus_files_id.*,content_sections.content_section_id.subcontent_sections.subhead_images.*,content_sections.content_section_id.subcontent_sections.sub_subcontent_sec_part.*,content_sections.content_section_id.subcontent_sections.sub_subcontent_sec_part.image.*&filter[slug][_eq]=${url}&access_token=${environment.token}&filter[status][_in]=published,draft`;
    //   console.log(pageurl);
    //   return this.http.get(pageurl)
    // }
    pageurl = environment?.apiURL + `/items/page?fields=*.*.*.*,testimonial.*,
    testimonial.testimonials.user_name,
    testimonial.testimonials.comment,
    testimonial.testimonials.company_name,
    testimonial.testimonials.company_logo.*,
    testimonial.testimonials.user_icon.*,
    testimonial.testimonials.user_role,
    testimonial.testimonials.extra_text_link,
    testimonial.testimonials.slug,
    testimonial.testimonials.button_link,
    testimonial.testimonials.button_link_text,
    testimonial.testimonials.class,
    testimonial.testimonials.user_class,
    testimonial.testimonials.tab_class,
    content_sections.*,content_sections.content_section_id.*,
    content_sections.content_section_id.head_icon.*,content_sections.content_section_id.bg_img.*,content_sections.content_section_id.image.*,
    content_sections.content_section_id.subcontent_sections.*,content_sections.content_section_id.subcontent_sections.subcontent_logo.*,
    content_sections.content_section_id.subcontent_sections.subhead_images.directus_files_id.*,
    content_sections.content_section_id.subcontent_sections.subhead_images.*,
    content_sections.content_section_id.subcontent_sections.sub_subcontent_sec_part.*,
    content_sections.content_section_id.subcontent_sections.sub_subcontent_sec_part.image.*&filter[slug][_eq]=${url}&filter[status][_in]=${this.filter}&access_token=` 
                  + environment?.token ;
    return this.http.get(pageurl)
  }

  // getCaseStories(slug:string){
  //   var CaseStorie = environment.apiURL + `/items/client_story?fields=*.*.*.*.*.*&filter[slug][_eq]=${slug}&access_token=`
  //                 + environment.token ;
  //   return this.http.get(CaseStorie)
  // }
  getCase(slug:string, type:string){
    var Case = environment?.apiURL + `/items/${type}?fields=*.*.*.*.*.*&filter[slug][_eq]=${slug}&filter[status][_in]=${this.filter}&access_token=`
                  + environment?.token ;
    return this.http.get(Case)
  }

  getCard(type:string){
    var Case = environment.apiURL + `/items/${type}?fields=*.*.*.*.*.*&filter[status][_in]=${this.filter}&access_token=`
                  + environment?.token ;
    return this.http.get(Case)
  }

  // getCaseBlogs(slug:string){
  //   var CaseBlog = environment.apiURL + `/items/blog?fields=*.*.*.*.*.*&filter[slug][_eq]=${slug}&access_token=`
  //                 + environment.token ;
  //   return this.http.get(CaseBlog)

  // }

  getHeader(){
    var pageurl = environment?.apiURL + 
                  `/items/header?fields=*.*,navigations.*,navigations.sub_navigation_ref.*&filter[status][_in]=${this.filter}&access_token=`
                  + environment?.token ;
    return this.http.get(pageurl)
  }

}
