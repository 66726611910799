<app-header></app-header>

<div class="client-hero">
     <div class="container" *ngFor="let item of banner">
          <h1 *ngIf="item?.heading">{{item?.heading}}</h1>
          <p *ngIf="item?.description">{{item?.description}}</p>
     </div>
</div>

<ng-container *ngFor="let item of FeaturedTools">
     <div class="client-cards">
          <div class="container">
               <h2 *ngIf="item?.name">{{item?.name}}</h2>
               <p *ngIf="item?.description">{{item?.description}}</p>
               <div class="row">
                    <div class="col-xl-3 col-lg-4 col-md-6 col-12" *ngFor="let subitem of item?.subcontent_sections">
                         <div class="card">
                              <img loading="lazy" *ngIf="subitem?.subcontent_logo" src="{{imgURL}}{{subitem?.subcontent_logo?.id}}" alt="{{subitem?.subcontent_logo_alt_text}}">
                         </div>
                    </div>
               </div>
          </div>
     </div>
</ng-container>
<app-testimonial></app-testimonial>