<app-header-two></app-header-two>

<div class="hero-solution-parts">
    <div class="custom--container " *ngFor="let item of banner">
            <h1 *ngIf="item.heading">{{item?.heading}}</h1>
            <p *ngIf="item.description">{{item?.description}}</p>
    </div>
</div>

<div class="sub-solution-cards mobile-none">
    <div class="custom--container">
        <div class="row">
            <div class="col-lg-4 col-12">
                <div class="card ml-auto">
                    <div class="sub-content" *ngFor="let item of customer">
                        <h2 *ngIf="item.heading">{{item?.heading}}</h2>
                        <p *ngIf="item.description">{{item?.description}}</p>
                        <!-- <ul>
                            <li *ngFor="let subitem of item.subcontent_sections"><span>{{subitem.sub_head}}</span></li>
                        </ul> -->
                        <div class="list"  >
                            <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex mb-10"><div><span class="list__icon"></span></div><div *ngIf="subitem.sub_head"><span class="list__item">{{subitem.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12">
                <div class="card">
                    <div class="sub-content" *ngFor="let item of life">
                        <h2 *ngIf="item.heading">{{item?.heading}}</h2>
                        <p *ngIf="item.description">{{item?.description}}</p>
                        <!-- <ul>
                            <li *ngFor="let subitem of item.subcontent_sections"><span>{{subitem.sub_head}}</span></li>
                        </ul> -->
                        <div class="list"  >
                            <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex  mb-10"><div><span class="list__icon"></span></div><div *ngIf="subitem.sub_head"><span class="list__item">{{subitem.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12">
                <div class="card mr-auto">
                    <div class="sub-content" *ngFor="let item of funnel">
                        <h2 *ngIf="item.heading">{{item?.heading}}</h2>
                        <p *ngIf="item.description">{{item?.description}}</p>
                        <!-- <ul>
                            <li *ngFor="let subitem of item.subcontent_sections"><span>{{subitem.sub_head}}</span></li>
                        </ul> -->
                        <div class="list"  >
                            <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex  mb-10"><div><span class="list__icon"></span></div><div *ngIf="subitem.sub_head"><span class="list__item">{{subitem.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!--integrate them and remove comment if integrated-->
<!-- mobile cards starts -->
<div class="sub-solution-cards web-none">
    <!-- container class remove from here -->
    <div>
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let item of customer">
                <div class="slide">
                    <div class="card">
                        <div class="sub-content">
                            <h2 *ngIf="item.heading">{{item?.heading}}</h2>
                            <p *ngIf="item.description">{{item?.description}}</p>
                            <!-- <ul>
                                <li *ngFor="let subitem of item.subcontent_sections"><span>{{subitem.sub_head}}</span></li>
                            </ul> -->
                            <div class="list"  >
                                <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex mb-23"><div><span class="list__icon"></span></div><div *ngIf="subitem.sub_head"><span class="list__item">{{subitem.sub_head}}</span></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide *ngFor="let item of life">
                <div class="slide">
                    <div class="card">
                        <div class="sub-content" *ngIf="item.heading">
                            <h2>{{item?.heading}}</h2>
                        <p *ngIf="item.description">{{item?.description}}</p>
                        <!-- <ul>
                            <li *ngFor="let subitem of item.subcontent_sections"><span>{{subitem.sub_head}}</span></li>
                        </ul> -->
                        <div class="list"  >
                            <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex mb-23"><div><span class="list__icon"></span></div><div *ngIf="subitem.sub_head"><span class="list__item">{{subitem.sub_head}}</span></div></div>
                        </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide *ngFor="let item of funnel">
                <div class="slide">
                    <div class="card">
                        <div class="sub-content" *ngIf="item.heading">
                            <h2>{{item?.heading}}</h2>
                        <p *ngIf="item.description">{{item?.description}}</p>
                        <!-- <ul>
                            <li *ngFor="let subitem of item.subcontent_sections"><span>{{subitem.sub_head}}</span></li>
                        </ul> -->
                        <div class="list"  >
                            <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex mb-23"><div><span class="list__icon"></span></div><div *ngIf="subitem.sub_head"><span class="list__item">{{subitem.sub_head}}</span></div></div>
                        </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>

<!-- Desktop View Starts -->
<div class="hero-desktop-bp">
    <div class="hero-common-content">
        <div class="custom--container">
            <div class="row">
                <div class="col-lg-6 col-12" *ngFor="let item of commerce">
                    <img loading="lazy" *ngIf="item.image" src="{{imgURL}}{{item.image?.id}}" alt="{{item.image_alt_text}}">
                </div>
                <div class="col-lg-6 col-12">
                    <div class="sub-content right" *ngFor="let item of commerce">
                        <h2 *ngIf="item.heading">{{item?.heading}}</h2>
                        <div>
                            <img loading="lazy" onerror="this.style.display='none'" class="divider" src='../../../assets/img/border.svg' alt="border">
                        </div>
                        <p *ngIf="item.description">{{item?.description}}</p>
                        <!-- <ul>
                            <li *ngFor="let subitem of item.subcontent_sections"><span>{{subitem.sub_head}}</span></li>
                        </ul> -->
                        <div class="list"  >
                            <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex mb-23 pr-50"><div><span class="list__icon"></span></div><div *ngIf="subitem.sub_head"><span class="list__item">{{subitem.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Desktop View Ends -->

<!-- Mobile View Starts -->

<div class="hero-mob-bp">
    <div class="hero-common-content ">
        <div class="custom--container">
            <div class="row">            
                <div class="col-lg-6 col-12">
                    <div class="sub-content right" *ngFor="let item of commerce">
                        <h2 *ngIf="item.heading">{{item?.heading}}</h2>
                        <div>
                            <img loading="lazy" onerror="this.style.display='none'" class="divider" src='../../../assets/img/border.svg' alt="border">
                        </div>
                        <p *ngIf="item.description">{{item?.description}}</p>
                        <!-- <ul>
                            <li *ngFor="let subitem of item.subcontent_sections"><span>{{subitem.sub_head}}</span></li>
                        </ul> -->
                        <div class="list"  >
                            <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex mb-23"><div><span class="list__icon"></span></div><div *ngIf="subitem.sub_head"><span class="list__item">{{subitem.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 d-flex justify-content-center" *ngFor="let item of commerce">
                    <img loading="lazy" *ngIf="item.image" src="{{imgURL}}{{item.image?.id}}" alt="{{item.image_alt_text}}">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Mobile View Ends -->

<!-- Sub-section-cards ends -->
<app-cards-slider></app-cards-slider>

<section class="section-bg-culture">
    <div class="container">
    <!-- <div class="custom--container"> -->
        <div class="culture">
            <div class="row" *ngFor="let item of expert">
                <div class="col-lg-3">
                    <h2 *ngIf="item.heading">{{item.heading}}</h2>
                    <div>
                        <img loading="lazy" onerror="this.style.display='none'" class="divider" src='../../../assets/img/border.svg' alt="border">
                    </div>
                </div>
                <div class="col-lg-9">
                    <p class="description" *ngIf="item.description">{{item?.description}}</p>
                    <p *ngIf="item.additional_line">{{item?.additional_line}}</p>
                    <div class="sub-content">
                        <!-- <ul>
                            <li *ngFor="let subitem of item.subcontent_sections"><span>{{subitem.sub_head}}</span></li>
                        </ul> -->

                        <div class="list ml-125"  >
                            <div *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex mb-23 pr-400"><div><span class="list__icon"></span></div><div *ngIf="subitem.sub_head"><span class="list__item">{{subitem.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- Testimonial web starts here  -->

<app-testimonial></app-testimonial>

<!-- Testimonial Desktop ends here -->


<!-- Blogs cards starts here  -->

<div style="margin-top: 30px;"></div>


<!-- Blogs cards starts here  -->

<app-related-case-study></app-related-case-study>

<!-- Blogs cards ends here  -->
<div class="mt-110" ></div>
<!-- Contact Us Starts here -->

<app-contact-alt></app-contact-alt>

<!-- Contact Us ends here -->