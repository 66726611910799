import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare function loadMeta(titVal:any,metaTitVal:any,metaDesVal:any ):any;
declare function pageMetaTypeURL(url: any, type: any): any;

@Component({
  selector: 'app-approach',
  templateUrl: './approach.component.html',
  styleUrls: ['./approach.component.scss']
})
export class ApproachComponent implements OnInit {

  imgURL = `${environment.apiURL}/assets/`
  allData:any;
  ContentSec:any;
  pageInfo:any;
  banner:any;
  testSec:any;
  contact:any;
  people:any;
  process:any;
  technology:any;
  titleValue:any='';
  metaTitVal:any='';
  metaDesVal:any='';
  pageUrl:any='';
  pageType:any='';

  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
  }

  ngOnInit() {
    let approachPageData = this.transferState.useScullyTransferState('approachTS', this.data.getPageData(this.router?.url?.split('?')[0]))
    approachPageData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections;
        this.pageInfo = this.allData;
        // console.log(this.pageInfo)
        this.titleValue = this.pageInfo?.title;
        this.metaTitVal = this.pageInfo?.meta_title_content;
        this.metaDesVal = this.pageInfo?.meta_description_content;
        loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);

        this.pageUrl = window?.location?.href?.split('?')[0];
        this.pageType = this.pageInfo?.metaOgType;
        pageMetaTypeURL(this.pageUrl, this.pageType);

        this.banner = Array.of(this.ContentSec?.[0]?.content_section_id); 
        this.people = Array.of(this.ContentSec?.[1]?.content_section_id); 
        this.process = Array.of(this.ContentSec?.[2]?.content_section_id); 
        this.technology = Array.of(this.ContentSec?.[3]?.content_section_id); 
        this.testSec = this.pageInfo?.testimonial?.testimonials;
        this.contact = Array.of(this.ContentSec?.[4]?.content_section_id);
      }
    });
  }

  ngOnDestroy(){
    let isMetaUrlAvailable = document.getElementById("metaURL");
    let isMetaTypeAvailable = document.getElementById("metaTYPE");
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }

  // for owl carousel
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    freeDrag:true,
    dots: false,
    navSpeed: 600,
    navText: ['<img loading="lazy"  src="assets/img/left-arrow.svg">','<img loading="lazy"  src="assets/img/right-arrow.svg">'],
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      760: {
        items: 2
      },
      1000: {
        items: 2
      }
    },
    nav: true
  }
  
}   