<app-header></app-header>


<!-- Hero-Section-common starts -->

<div class="hero-section-common" *ngIf="banner">
    <div class="container">
        <div class="row" *ngFor="let sec of banner">
            <div class="col-lg-5 col-12">
                <div class="content">
                    <h1 *ngIf="sec.heading">{{sec?.heading}}</h1>
                    <p *ngIf="sec.description">{{sec?.description}}</p>
                </div>
            </div>
            <!-- web-cards -->
            <div class="col-lg-7 col-12 mobile-none">
                <div class="row ml-lg-1">
                    <div class="col-lg-6">
                        <div class="card br-bottom" (click)="scrollToPosition('#card1')">
                            <div class="card__text" *ngFor="let subSec of sec?.subcontent_sections; let i = index">
                                <h4 class="card__title" *ngIf="i==0 && subSec.sub_head">{{subSec.sub_head}}</h4>
                                <p class="card__para" *ngIf="i==0 && subSec.sub_head_description">{{subSec.sub_head_description}}</p>
                                <!-- <div class="learn__more"> -->
                                    <a class="card__link" *ngIf="i==0 && subSec.extra_link_text" href="javascript:;">{{subSec.extra_link_text}}
                                        <img onerror="this.style.display='none'" class="card__img" src="assets/img/homepage/right-arrow.png" alt="right arrow">
                                    </a>
                                    <!-- </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 pl-lg-0">
                        <div class="card br-bottom" (click)="scrollToPosition('#card2')">
                            <div class="card__text" *ngFor="let subSec of sec?.subcontent_sections; let i = index">
                                <h4 class="card__title" *ngIf="i==1 && subSec.sub_head">{{subSec.sub_head}} </h4>
                                <p class="card__para mb-52" *ngIf="i==1 && subSec.sub_head_description">{{subSec.sub_head_description}}</p>
                                <div class="learn__more">
                                <a class="card__link " *ngIf="i==1 && subSec.extra_link_text" href="javascript:;">{{subSec.extra_link_text}}
                                    <img onerror="this.style.display='none'" class="card__img" src="assets/img/homepage/right-arrow.png" alt="right-arrow">
                                    </a>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card br-bottom card-mg" (click)="scrollToPosition('#card3')">
                            <div class="card__text" *ngFor="let subSec of sec?.subcontent_sections; let i = index">
                                <h4 class="card__title" *ngIf="i==2 && subSec.sub_head">{{subSec.sub_head}}</h4>
                                <p class="card__para mb-52" *ngIf="i==2 && subSec.sub_head_description">{{subSec.sub_head_description}}</p>
                                <a class="card__link" *ngIf="i==2 && subSec.extra_link_text" href="javascript:;">{{subSec.extra_link_text}}<img
                                        class="card__img" src="assets/img/homepage/right-arrow.png" alt="right arrow"></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 pl-lg-0">
                        <div class="card br-bottom card-mg" (click)="scrollToPosition('#card4')">
                            <div class="card__text" *ngFor="let subSec of sec?.subcontent_sections; let i = index">
                                <h4 class="card__title" *ngIf="i==3 && subSec.sub_head">{{subSec.sub_head}}</h4>
                                <p class="card__para mb-52" *ngIf="i==3 && subSec.sub_head_description">{{subSec.sub_head_description}}</p>
                                <a class="card__link" *ngIf="i==3 && subSec.extra_link_text" href="javascript:;">{{subSec.extra_link_text}}<img
                                        class="card__img" onerror="this.style.display='none'" src="assets/img/homepage/right-arrow.png" alt="right arrow"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
            
        </div>
    </div>
    <!-- for mobile cards  -->
    <ng-container *ngFor="let sec of banner">
        <owl-carousel-o [options]="customOptions" class="web-none">
            <ng-template carouselSlide *ngFor="let sub_item of sec?.subcontent_sections;let i=index;">
                <div class="slide">
                    <div class="card">
                        <h4 *ngIf="sub_item.sub_head">{{sub_item.sub_head}}</h4>
                        <p *ngIf="sub_item.sub_head_description">{{sub_item.sub_head_description}}</p>
                        <a href="{{sub_item?.slug}}" *ngIf="sub_item.extra_link_text">{{sub_item.extra_link_text}}
                            <img loading="lazy" onerror="this.style.display='none'" src="../../../assets/img/right-arrow.svg" alt="right arrow">
                        </a>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </ng-container>
</div>

<!-- Hero-section-common ends  -->

<!-- Hero-section-common content starts  For Desktop View -->

<div class="hero-desktop-bp">
    <div class="hero-common-content" id="card1" *ngIf="sec1">
        <div class="container p__i-30">
            <div class="row">
                <div class="col-lg-6 col-12" *ngFor="let data of sec1">
                    <img class="left__img" loading="lazy" *ngIf="data?.image" src="{{imgURL}}{{data?.image?.id}}" alt="{{data?.image_alt_text}}">
                </div>
                <div class="col-lg-6 col-12 pl__110">
                    <div class="sub-content right" *ngFor="let data of sec1">
                        <h2 *ngIf="data.heading">{{data.heading}}</h2>
                        <div>
                            <img loading="lazy" onerror="this.style.display='none'" class="divider" src='../../../assets/img/border.svg' alt="border">
                        </div>
                        <p class="right__text" *ngIf="data.description">{{data.description}}</p>
                        <div class="list" *ngFor="let subPoint of data?.subcontent_sections; let i = index">
                            <div *ngIf="i===0 && subPoint.sub_head" class="grid grid-list-col mb-23 pr-150"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===1 && subPoint.sub_head" class="grid grid-list-col mb-23 pr-150"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===2 && subPoint.sub_head" class="grid grid-list-col mb-23 pr-150"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-common-content" id="card2" *ngIf="sec2">
        <div class="container p__i-30">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="sub-content left" *ngFor="let data of sec2">
                        <h2 *ngIf="data.heading">{{data?.heading}}</h2>
                        <div>
                            <img loading="lazy" onerror="this.style.display='none'" class="divider" src='../../../assets/img/border.svg' alt="border">
                        </div>
                        <p class="left__text" *ngIf="data.description">{{data.description}}</p>
                        <div class="list" *ngFor="let subPoint of data?.subcontent_sections; let i = index">
                            <div *ngIf="i===0 && subPoint.sub_head" class="grid grid-list-col mb-23 pr-250"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===1 && subPoint.sub_head" class="grid grid-list-col mb-23 pr-250"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===2 && subPoint.sub_head" class="grid grid-list-col mb-23 pr-250"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12" *ngFor="let data of sec2">
                    <img class="right__img" loading="lazy" *ngIf="data.image" src="{{imgURL}}{{data?.image?.id}}" alt="{{data?.image_alt_text}}">
                </div>
            </div>
        </div>
    </div>
    <div class="hero-common-content" id="card3" *ngIf="sec3">
        <div class="container p__i-30">
            <div class="row">
                <div class="col-lg-6 col-12" *ngFor="let data of sec3">
                    <img class="left__img" loading="lazy" *ngIf="data.image" src="{{imgURL}}{{data?.image?.id}}" alt="{{data?.image_alt_text}}">
                </div>
                <div class="col-lg-6 col-12 pl__110">
                    <div class="sub-content right" *ngFor="let data of sec3">
                        <h2 *ngIf="data.heading">{{data.heading}}</h2>
                        <div>
                            <img loading="lazy" onerror="this.style.display='none'" class="divider" src='../../../assets/img/border.svg' alt="border">
                        </div>
                        <p class="right__text" *ngIf="data.description">{{data.description}}</p>
                        <div class="list" *ngFor="let subPoint of data?.subcontent_sections; let i = index">
                            <div *ngIf="i===0 && subPoint.sub_head" class="grid grid-list-col mb-23 pr-150"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===1 && subPoint.sub_head" class="grid grid-list-col mb-23 pr-150"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===2 && subPoint.sub_head" class="grid grid-list-col mb-23 pr-150"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-common-content" id="card4" *ngIf="sec4">
        <div class="container p__i-30 ">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="sub-content left" *ngFor="let data of sec4">
                        <h2 *ngIf="data.heading">{{data?.heading}}</h2>
                        <div>
                            <img loading="lazy" onerror="this.style.display='none'" class="divider" alt="border" src='../../../assets/img/border.svg'>
                        </div>
                        <p class="left__text" *ngIf="data.description">{{data?.description}}</p>
                        <div class="list" *ngFor="let subPoint of data?.subcontent_sections; let i = index">
                            <div *ngIf="i===0 && subPoint.sub_head" class="grid grid-list-col mb-23 pr-250"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===1 && subPoint.sub_head" class="grid grid-list-col mb-23 pr-250"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===2 && subPoint.sub_head" class="grid grid-list-col mb-23 pr-250"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12" *ngFor="let data of sec4">
                    <img class="right__img" loading="lazy" *ngIf="data.image" src="{{imgURL}}{{data?.image?.id}}" alt="{{data?.image_alt_text}}">
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Hero-section-common content ends For Desktop View -->

<!-- Hero-section-common content starts  For Mobile View -->

<div class="hero-mob-bp">
    <div class="hero-common-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="sub-content right" *ngFor="let data of sec1">
                        <h2 *ngIf="data.heading">{{data.heading}}</h2>
                        <div>
                            <img loading="lazy" onerror="this.style.display='none'" class="divider" alt="boder" src='../../../assets/img/border.svg'>
                        </div>
                        <p *ngIf="data.description">{{data.description}}</p>
                        <div class="list" *ngFor="let subPoint of data?.subcontent_sections; let i = index">
                            <div *ngIf="i===0 && subPoint.sub_head" class="grid grid-list-col mb-19"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===1 && subPoint.sub_head" class="grid grid-list-col mb-19"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===2 && subPoint.sub_head" class="grid grid-list-col mb-19"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12" *ngFor="let data of sec1">
                    <div class="contentImg">
                        <img class="mt__30" loading="lazy" *ngIf="data.image" src="{{imgURL}}{{data?.image?.id}}" alt="{{data?.image_alt_text}}">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hero-common-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="sub-content left" *ngFor="let data of sec2">
                        <h2 *ngIf="data.heading">{{data.heading}}</h2>
                        <div>
                            <img loading="lazy" onerror="this.style.display='none'" class="divider" alt="boder" src='../../../assets/img/border.svg'>
                        </div>
                        <p *ngIf="data.description">{{data.description}}</p>
                        <div class="list" *ngFor="let subPoint of data?.subcontent_sections; let i = index">
                            <div *ngIf="i===0 && subPoint.sub_head" class="grid grid-list-col mb-19"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===1 && subPoint.sub_head" class="grid grid-list-col mb-19"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===2 && subPoint.sub_head" class="grid grid-list-col mb-19"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12" *ngFor="let data of sec2">
                    <div class="contentImg">
                        <img class="mt__30" loading="lazy" *ngIf="data.image" src="{{imgURL}}{{data?.image?.id}}" alt="{{data?.image_alt_text}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-common-content">
        <div class="container">
            <div class="row">

                <div class="col-lg-6 col-12">
                    <div class="sub-content right" *ngFor="let data of sec3">
                        <h2 *ngIf="data.heading">{{data.heading}}</h2>
                        <div>
                            <img loading="lazy" onerror="this.style.display='none'" class="divider" src='../../../assets/img/border.svg' alt="border">
                        </div>
                        <p *ngIf="data.description">{{data.description}}</p>
                        <div class="list" *ngFor="let subPoint of data?.subcontent_sections; let i = index">
                            <div *ngIf="i===0 && subPoint.sub_head" class="grid grid-list-col mb-19"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===1 && subPoint.sub_head" class="grid grid-list-col mb-19"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===2 && subPoint.sub_head" class="grid grid-list-col mb-19"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12" *ngFor="let data of sec3">
                    <div class="contentImg">
                        <img class="mt__30" loading="lazy" *ngIf="data.image" src="{{imgURL}}{{data?.image?.id}}" alt="{{data?.image_alt_text}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-common-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="sub-content left" *ngFor="let data of sec4">
                        <h2 *ngIf="data.heading">{{data.heading}}</h2>
                        <div>
                            <img loading="lazy" onerror="this.style.display='none'" class="divider" src='../../../assets/img/border.svg' alt="border">
                        </div>
                        <p *ngIf="data.description">{{data.description}}</p>
                        <!-- <ul *ngFor="let subPoint of data.subcontent_sections; let i = index">
                            <li *ngIf="i===0"><span>{{subPoint.sub_head}}</span></li>
                            <li *ngIf="i===1"><span>{{subPoint.sub_head}}</span></li>
                            <li *ngIf="i===2"><span>{{subPoint.sub_head}}</span></li>
                        </ul> -->
                        <div class="list" *ngFor="let subPoint of data?.subcontent_sections; let i = index">
                            <div *ngIf="i===0 && subPoint.sub_head" class="grid grid-list-col mb-19"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===1 && subPoint.sub_head" class="grid grid-list-col mb-19"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                            <div *ngIf="i===2 && subPoint.sub_head" class="grid grid-list-col mb-19"><div><span class="list__icon"></span></div><div><span class="list__item">{{subPoint.sub_head}}</span></div></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12" *ngFor="let data of sec4"> 
                    <div class="contentImg">
                        <img class="mt__30" loading="lazy" *ngIf="data.image" src="{{imgURL}}{{data?.image?.id}}" alt="{{data?.image_alt_text}}">
                
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Hero-section-common content ends For Mobile View -->

<!-- Testimonial web starts here  -->

<app-testimonial></app-testimonial>

<!-- Testimonial Desktop ends here -->

<div style="margin-top: 50px;"></div>

<!-- Blogs cards starts here  -->

<app-related-resources></app-related-resources>



<!-- Blogs cards ends here  -->

<!-- Page-Swiping Section starts here  -->

<div style="margin-top: 80px;"></div>

<app-navigation-sec></app-navigation-sec>


<!-- Page-Swiping Section ends here  -->

<div class="mt-70"></div>

<app-contact-alt></app-contact-alt>
