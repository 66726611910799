import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';

@Component({
  selector: 'app-why-brooklyn',
  templateUrl: './why-brooklyn.component.html',
  styleUrls: ['./why-brooklyn.component.scss']
})
export class WhyBrooklynComponent implements OnInit {
  imgURL = `${environment.apiURL}/assets/`
  allData:any;
  BDC:any;
  ContentSec:any;
  pageInfo:any;
  @Input() homepadtop='';

  // for owl carousel
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    freeDrag:true,
    dots: true,
    navSpeed: 600,
    navText: ['<img loading="lazy"  src="assets/img/left-arrow.svg">','<img loading="lazy"  src="assets/img/right-arrow.svg">'],
    responsive: {
      0: {
        items: 1 
      },
      600: {
        items: 2
      },
      760: {
        items: 2
      },
      1000: {
        items: 3
      }
    },
    nav: true
  }
  
  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  ngOnInit(): void {
    let whyBDCData = this.transferState.useScullyTransferState('relatedData', this.data.getPageData(this.router?.url?.split('?')[0]));
    whyBDCData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections;
        for(let i=0; i<=this.ContentSec.length ; i++){
          if(this.ContentSec?.[i]?.content_section_id?.common_name === "why BDC" ){
            this.BDC = Array.of(this.ContentSec?.[i]?.content_section_id);
          }
        }
      }        
    })
  }

}
