<app-header></app-header>


<!-- Hero-Section-solution starts -->

<div class="hero-section-solution" *ngIf="banner">
    <div class="custom--container" *ngFor="let item of banner">
        <h1 *ngIf="item.heading">{{item.heading}}</h1>
        <p *ngIf="item.description">{{item.description}}</p>
    </div>
</div>

<!-- Hero-section-solution ends  -->

<!-- Sub-section-cards starts -->

<div class="sub-section-cards">
    <div class="container" style="max-width: 1250px;">
        <div class="row justify-content-center" *ngFor="let item of banner">
            <!-- change {{sub_item?.class}} to "col-12 col-lg-6" in below class -->
            <div class="col-12 col-lg-6 d-flex justify-content-center" *ngFor="let subitem of item?.subcontent_sections" >
                <a href="{{subitem.slug}}" style="text-decoration: none;">
                <div class="card__text ">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="card-img">
                                <img loading="lazy" *ngIf="subitem.subcontent_logo" src="{{imgURL}}{{subitem.subcontent_logo?.id}}" alt="{{subitem?.subcontent_logo_alt_text}}">
                            </div>
                        </div>
                        <div class="col-md-10">
                            <h4 *ngIf="subitem.sub_head">{{subitem?.sub_head}}</h4>
                            <p *ngIf="subitem.sub_head_description">{{subitem?.sub_head_description}}</p>
                            <div class="learn__more" *ngIf="subitem.extra_link_text">

                                <a class="link" aria-label="solution" href="{{subitem.slug}}">{{subitem?.extra_link_text}} 
                                    <!-- <img
                                    src="assets/img/homepage/right-arrow.png" alt=""> -->
                                </a>
                                </div>
                        </div>
                    </div>
                </div>
                </a>
            </div>
        </div>
    </div>
</div>

<!-- Sub-section-cards ends -->
<app-cards-slider></app-cards-slider>


<div style="margin-top: 40px;"></div>
    
<app-why-brooklyn homepadtop="solution-Bg"></app-why-brooklyn>


<!-- Section 2 ends -->

<div style="margin-top: 80px;"></div>

<!-- Contact alt Starts here -->

<div class="pb-0" *ngFor="let item of contact">
    <div class="contact-alt">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="content">
                        <h6 *ngIf="item.name">{{item.name}}</h6>
                        <h3 *ngIf="item.heading">{{item.heading}}</h3>
                    </div>
                </div>
                <div class="col-lg-7 bg-show" >
                    <form [formGroup]="checkoutForm" #subForm (ngSubmit) = "onClickSubmit(checkoutForm.value);">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group floating">
                                    <input type="text" class="form-control floating" placeholder=" " name="uName" id="name" formControlName="uName" [ngClass]="{'errorBorder' : form2clicked && (checkoutForm.controls.uName.errors?.required)}" autocomplete="off">
                                    <label for="name" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uName.errors?.required)}">Name</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group floating">
                                    <div>
                                        <p class="error-text" *ngIf="form2clicked && (checkoutForm.controls.uEmail.errors?.pattern)">Incorrect Email. Please try again. </p>
                                    </div>
                                    <input type="text" class="form-control floating" placeholder=" " id="email" name="uEmail" formControlName="uEmail" [ngClass]="{'errorBorder' : form2clicked && (checkoutForm.controls.uEmail.errors?.pattern || checkoutForm.controls.uEmail.errors?.required)}" autocomplete="off">
                                    <label for="email" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uEmail.errors?.pattern || checkoutForm.controls.uEmail.errors?.required)}">Email</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group floating">
                                    <input type="text" class="form-control floating" placeholder=" " id="company" name="uCompany" formControlName="uCompany" [ngClass]="{'errorBorder' : form2clicked && (checkoutForm.controls.uCompany.errors?.required)}" autocomplete="off">
                                    <label for="company" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uCompany.errors?.required)}">Company</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group floating">
                                    <input type="text" class="form-control floating" placeholder=" " id="role" name="uRole" formControlName="uRole" [ngClass]="{'errorBorder' : form2clicked && (checkoutForm.controls.uRole.errors?.required)}" autocomplete="off">
                                    <label for="role" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uRole.errors?.required)}">Role</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group message floating">
                                    <textarea class="form-control floating" rows="5" placeholder=" " id="message" name="uMessage" formControlName="uMessage" [ngClass]="{'errorMsgBorder' : form2clicked && (checkoutForm.controls.uMessage.errors?.required)}"></textarea>
                                    <label for="message" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uMessage.errors?.required)}">Message</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label" for="vehicle1">Subscribe to Brooklyn Data Co. Newsletter</label>
                            <input type="checkbox" class="form-check-input" id="vehicle1" name="uCheck"  formControlName="uCheck" value="Bike">
                        </div>
                        <div>
                            <p class="error-text2" *ngIf="form2clicked && (checkoutForm.controls.uEmail.errors?.required || checkoutForm.controls.uName.errors?.required || checkoutForm.controls.uCompany.errors?.required)">All fields must be completed. </p>
                        </div>
                        <button aria-label="button" type = "submit"  class="btn btns" *ngIf="item.button_label" >{{item.button_label}}</button>
                    </form>
                </div>
            </div>
        </div>
        <div class="contact-img"></div>
    </div>
</div>


<!-- Contact alt ends here -->
