<app-header></app-header>

<!-- Hero-Section-solution starts -->
<div *ngFor="let item of contact">
    <div class="get-in-touch">
        <div class="container">
            <h2>{{item?.name}}</h2>
            <div class="d-flex justify-content-center">
                <img loading="lazy" onerror="this.style.display='none'" src="../../../assets/img/plane.svg" alt="plane">
                <p>{{item?.additional_line}}</p>
            </div>
        </div>
    </div>

    <!-- Contact alt Starts here -->
    <div class="loginForm pb-lg-5 pb-0 mt-lg-5">
        <div class="contact-alt">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 mobile-none">
                        <img loading="lazy" *ngIf="item?.image" src="{{imgURL}}{{item?.image?.id}}" alt="{{item?.image_alt_text}}">
                    </div>
                    <div class="col-lg-6">
                        <form [formGroup]="checkoutForm" #subForm (ngSubmit) = "onClickSubmit(checkoutForm.value);">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group floating">
                                        <input type="text" class="form-control floating" placeholder=" " name="uName" id="name" formControlName="uName" [ngClass]="{'errorBorder' : form2clicked && (checkoutForm.controls.uName.errors?.required)}" autocomplete="off">
                                        <label for="name" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uName.errors?.required)}">Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group floating">
                                        <div>
                                            <p class="error-text" *ngIf="form2clicked && (checkoutForm.controls.uEmail.errors?.pattern)">Incorrect Email. Please try again. </p>
                                        </div>
                                        <input type="text" class="form-control floating" placeholder=" " id="email" name="uEmail" formControlName="uEmail" [ngClass]="{'errorBorder' : form2clicked && (checkoutForm.controls.uEmail.errors?.pattern || checkoutForm.controls.uEmail.errors?.required)}" autocomplete="off">
                                        <label for="email" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uEmail.errors?.pattern || checkoutForm.controls.uEmail.errors?.required)}">Email</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group floating">
                                        <input type="text" class="form-control floating" placeholder=" " id="company" name="uCompany" formControlName="uCompany" [ngClass]="{'errorBorder' : form2clicked && (checkoutForm.controls.uCompany.errors?.required)}" autocomplete="off">
                                        <label for="company" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uCompany.errors?.required)}">Company</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group floating">
                                        <input type="text" class="form-control floating" placeholder=" " id="role" name="uRole" formControlName="uRole" [ngClass]="{'errorBorder' : form2clicked && (checkoutForm.controls.uRole.errors?.required)}" autocomplete="off">
                                        <label for="role" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uRole.errors?.required)}">Role</label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group message floating">
                                        <textarea class="form-control floating" rows="5" placeholder=" " id="message" name="uMessage" formControlName="uMessage" [ngClass]="{'errorMsgBorder' : form2clicked && (checkoutForm.controls.uMessage.errors?.required)}"></textarea>
                                        <label for="message" [ngClass]="{'errorLabel' : form2clicked && (checkoutForm.controls.uMessage.errors?.required)}">Message</label>
                                        <div>
                                            <p class="error-text2" *ngIf="form2clicked && (checkoutForm.controls.uEmail.errors?.required || checkoutForm.controls.uName.errors?.required || checkoutForm.controls.uCompany.errors?.required)">All fields must be completed. </p>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="col-md-12">
                                    <div class="form-check">
                                        <label class="form-check-label" for="subscribe">Subscribe to Brooklyn Data Co. Newsletter
                                        </label>
                                        <div>
                                            <input type="checkbox" class="form-check-input" id="subscribe" name="uCheck"  formControlName="uCheck" value="yes">
                                            <span class="geekmark"></span>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            <button aria-label="button" type = "submit"  class="btn btns mt-lg-4" *ngIf="item?.button_label" >{{item.button_label}}</button>
                        </form>
                    </div>
                    <div class="col-lg-6 text-center web-none">
                        <img loading="lazy" onerror="this.style.display='none'" src="{{imgURL}}{{item?.image?.id}}" alt="{{item?.image_alt_text}}">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="login-content">
        <div class="page-swip">
            <div class="container">
                <h4 *ngIf="item?.heading">{{item?.heading}}</h4>
                <p *ngIf="item?.description">{{item?.description}}</p>
                <div class="text-center">
                    <a href="{{item.extra_link}}" *ngIf="item?.extra_link_text">{{item?.extra_link_text}}<img loading="lazy" onerror="this.style.display='none'" src="assets/img/homepage/right-arrow.png" class="blog-arrow" alt="right-arrow"></a>
                </div>
            </div>
        </div>
    </div>
</div>