<!-- Contact Us Starts here -->

<section class="pb-0" *ngIf="contactSec">
    <div class="contact-sec" *ngFor="let item of contactSec">
        <div class="contact-sec2">
            <div class="container">
                <div class="content">
                    <h6 *ngIf="item.additional_line">{{item?.additional_line}}</h6>
                    <h3 *ngIf="item.heading">{{item?.heading}}</h3>
                    <button class="btn btns mt-lg-4" (click)="goToPage('/contact')" *ngIf="item?.button_label">{{item.button_label}}</button>
                </div>
            </div>
        </div>
    </div>  
</section>

<!-- Contact Us ends here -->