import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TransferStateService } from '@scullyio/ng-lib';

declare function loadMeta(titVal:any,metaTitVal:any,metaDesVal:any ):any;
declare function pageMetaTypeURL(url: any, type: any): any;

@Component({
  selector: 'app-solution-internal',
  templateUrl: './solution-internal.component.html',
  styleUrls: ['./solution-internal.component.scss']
})
export class SolutionInternalComponent implements OnInit {
  imgURL = `${environment.apiURL}/assets/`
  allData:any;
  ContentSec:any;
  pageInfo:any;
  banner:any;
  customer:any;
  life:any;
  funnel:any;
  commerce:any;
  expert:any;
  testSec:any;
  relatedCase:any;
  contact: any;
  keywordsArray:any;
  titleValue:any='';
  metaTitVal:any='';
  metaDesVal:any='';
  pageUrl:any='';
  pageType:any='';
  cardSlider: any;
  
  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
  }

  ngOnInit() {
    let solutionInternalData = this.transferState.useScullyTransferState('solutionInternalTS', this.data.getPageData(this.router?.url?.split('?')[0]));
    solutionInternalData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections
        this.pageInfo = this.allData
        this.titleValue = this.pageInfo?.title
        this.metaTitVal = this.pageInfo?.meta_title_content
        this.metaDesVal = this.pageInfo?.meta_description_content
        loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);
        
        this.pageUrl = window?.location?.href;
        this.pageType = this.pageInfo?.metaOgType;
        pageMetaTypeURL(this.pageUrl, this.pageType);

        for(let sectionType of this.ContentSec){
          let sectionTypeValue = sectionType?.content_section_id?.section_type ;
          switch(sectionTypeValue){
            case 'banner' :
              this.banner = Array.of(sectionType?.content_section_id) //banner section detail
              break;
            case 'subBanner1' :
              this.customer = Array.of(sectionType?.content_section_id) //banner section detail
              break;
            case 'subBanner2' :
              this.life = Array.of(sectionType?.content_section_id) //banner section detail
              break;
            case 'subBanner3' :
              this.funnel = Array.of(sectionType?.content_section_id) //banner section detail
              break;
            case 'highlight' :
              this.commerce = Array.of(sectionType?.content_section_id) //banner section detail
              break;
            case 'expert' :
              this.expert = Array.of(sectionType?.content_section_id) //banner section detail
              break;
            // case 'solution-eCommerce-relatedCaseStudy' :
            //   this.relatedCase = Array.of(sectionType?.content_section_id) //banner section detail
            //   console.log(this.relatedCase);
            //   break;
          }
        }

        // for(let i=0; i<this.relatedCase[0].subcontent_sections.length; i++){
        //   this.relatedCase[0].subcontent_sections[i].keywordsArray= this.relatedCase?.[0]?.subcontent_sections?.[i]?.keywords?.split(', ')
        //   console.log(this.relatedCase[0].subcontent_sections[i].keywordsArray)
        // }
      }
    });
  }
  // for owl carousel
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    freeDrag:true,
    dots: false,
    dotsEach: false,
    navSpeed: 600,
    navText: ['<img loading="lazy"  src="assets/img/left-arrow.svg">','<img loading="lazy"  src="assets/img/right-arrow.svg">'],
    responsive: {
      0: {
        items: 1
      },
      325:{
        items: 1.2
      },
      500: {
        items: 1.2
      },
      768: {
        items: 2
      },
      1000: {
        items: 4
      }
    },
    nav: true
  }
  ngOnDestroy(){
    loadMeta('', '', '');
    let isMetaUrlAvailable = document.getElementById("metaURL");
    let isMetaTypeAvailable = document.getElementById("metaTYPE");
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }
}
