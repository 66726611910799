import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ApproachComponent } from './pages/approach/approach.component';
import { AuditComponent } from './pages/audit/audit.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { CareersComponent } from './pages/careers/careers.component';
import { CaseStudiesComponent } from './pages/case-studies/case-studies.component';
import { ClientComponent } from './pages/client/client.component';
import { ContactComponent } from './pages/contact/contact.component';
import { GeneralPageComponent } from './pages/general-page/general-page.component';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ServicesComponent } from './pages/services/services.component';
import { SolutionInternalComponent } from './pages/solution-internal/solution-internal.component';
import { SolutionComponent } from './pages/solution/solution.component';
import { StoriesComponent } from './pages/stories/stories.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { ToolsInternalComponent } from './pages/tools-internal/tools-internal.component';
import { ToolsComponent } from './pages/tools/tools.component';


const routes: Routes = [
  {
    path:'',
    component:HomeComponent
  },
  {
    path:'approach',
    component:ApproachComponent
  },
  {
    path:'services/:internalType',
    component:ServicesComponent
  },
  {
    path:'tools',
    component:ToolsComponent
  },
  {
    path:'tools/:internalType',
    component:ToolsInternalComponent
  },
  {
    path:'solution',
    component:SolutionComponent
  },
  {
    path:'solution/:internalType',
    component:SolutionInternalComponent
  },
  {
    path:'blog',
    component:BlogsComponent
  },
  {
    path:'blog/:num',
    component:CaseStudiesComponent
  },
  {
    path:'careers',
    component:CareersComponent
  },
  
  {
    path:'contact',
    component:ContactComponent
  },
  {
    path:'case-studies',
    component:CaseStudiesComponent
  },
  {
    path:'stories/:num',
    component:CaseStudiesComponent
  },
  {
    path:'stories',
    component:StoriesComponent
  },
  {
    path:'thank-you',
    component:ThankYouComponent
  },
  {
    path:'about',
    component:AboutComponent
  },
  {
    path:'discovery',
    component:AuditComponent
  },
  {
    path:'privacy',
    component:GeneralPageComponent
  },
  {
    path:'termsofuse',
    component:GeneralPageComponent
  },
  {
    path:'404',
    component:PageNotFoundComponent
  },
  {
    path:'clients',
    component:ClientComponent
  },
  {
    path:'**',
    component:PageNotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
