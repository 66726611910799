<app-header></app-header>

<!-- Hero Section starts -->
<ng-container *ngFor="let item of banner">
    <div class="hero" style="background-image: url('{{imgURL}}{{item?.bg_img?.filename_disk}}')">
        <div class="container">
            <div class="row">
                <div class="col-xl-10 col-lg-12 col-12">
                    <div class="content ">
                        <span class="sub-heading" *ngIf="item?.additional_line">{{item?.additional_line}}</span>
                        <h2 *ngIf="item?.heading">{{item.heading}} <span class="underline" *ngIf="item?.extra_link_text">{{item.extra_link_text}}</span></h2>
                        <p *ngIf="item?.description">{{item.description}}</p>
                        <button class="btn btns" (click)="goToPage('contact')" *ngIf="item?.button_label">{{item.button_label}}</button>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-12 mobile-none">
                    <div class="hero-img">
                        <img loading="lazy" *ngIf="item?.image" alt="{{item.image_alt_text}}" src="{{imgURL}}{{item?.image?.id}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- Hero section ends  -->

<app-cards-slider></app-cards-slider>

<section *ngIf="approach">
    <div class="section1" *ngFor="let item of approach">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12 order-lg-1 order-sm-1 order-2">
                    <div class="sec-img">
                        <img loading="lazy" *ngIf="item?.image" src="{{imgURL}}{{item?.image?.id}}" alt="{{item.image_alt_text}}">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12 order-lg-2 order-sm-2 order-1">
                    <div class="sec-content">
                        <h2 *ngIf="item?.heading">{{item.heading}}</h2>
                        <p class="description-content" *ngIf="item?.description">{{item.description}}</p>
                        <div class="sec-points" *ngFor="let sub_item of item?.subcontent_sections">
                            <img loading="lazy" *ngIf="sub_item.subcontent_logo" alt="{{sub_item.subcontent_logo_alt_text}}" src="{{imgURL}}{{sub_item.subcontent_logo?.id}}">
                            <div class="content">
                                <h4 *ngIf="sub_item.sub_head">{{sub_item?.sub_head}}</h4>
                                <p *ngIf="sub_item.sub_head_description"> {{sub_item?.sub_head_description}} </p>
                            </div>
                        </div>
                        <button class="btns mt-lg-5 mt-5" (click)="goToPage('approach')" *ngIf="item?.button_label">
                            {{item.button_label}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Section 2 starts here -->

<app-why-brooklyn homepadtop="sectionHomePad"></app-why-brooklyn>

<!-- Testimonial starts here  -->

<app-testimonial></app-testimonial>

<!-- Section 3 starts here -->

<section class="section3-bg" *ngIf="solutionAndBDC">
    <div class="section3" *ngFor="let item of solutionAndBDC">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="content">
                        <h2 *ngIf="item?.heading">{{item.heading}}</h2>
                        <p *ngIf="item?.description">{{item.description}}</p>
                        <button class="btn btns"
                            (click)="goToPage(item.button_link)" *ngIf="item?.button_label">{{item.button_label}}</button>
                        <img loading="lazy" *ngIf="item?.image" src="{{imgURL}}{{item?.image?.id}}" alt="{{item.image_alt_text}}" class="mobile-none">
                    </div>

                </div>
                <div class="col-lg-6 col-12 mobile-none">
                    <div class="row">
                        <div class="col-lg-6" *ngFor="let sub_item of item?.subcontent_sections;let i=index;">
                            <a href="{{sub_item?.slug}}" style="text-decoration: none;">
                                <div class="card card{{i+1}} br-bottom">
                                    <img loading="lazy" *ngIf="sub_item?.subcontent_logo" src="{{imgURL}}{{sub_item?.subcontent_logo?.id}}" alt="{{sub_item?.subcontent_logo_alt_text}}">
                                    <h4 *ngIf="sub_item?.sub_head">{{sub_item?.sub_head}}</h4>
                                    <p *ngIf="sub_item?.sub_head_description">{{sub_item?.sub_head_description}}</p>
                                    <a href="{{sub_item?.slug}}" *ngIf="sub_item?.extra_link_text">{{sub_item?.extra_link_text}} <img
                                        onerror="this.style.display='none'" src="../../../assets/img/right-arrow.svg" alt="arrow"></a>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <!-- cards mobile view -->

        <div class="web-none">
            <div class="slide">
                <div class="card"*ngFor="let sub_item of item?.subcontent_sections;let i=index;" >
                    <img loading="lazy" *ngIf="sub_item?.subcontent_logo" src="{{imgURL}}{{sub_item?.subcontent_logo?.id}}" alt="{{sub_item?.subcontent_logo_alt_text}}">
                    <h4 *ngIf="sub_item?.sub_head">{{sub_item?.sub_head}}</h4>
                    <p *ngIf="sub_item?.sub_head_description">{{sub_item?.sub_head_description}}</p>
                    <a href="{{sub_item?.slug}}" *ngIf="sub_item?.extra_link_text">{{sub_item?.extra_link_text}}
                        <img loading="lazy" onerror="this.style.display='none'" src="../../../assets/img/right-arrow.svg" alt="arrow">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>


<app-contact-alt></app-contact-alt>