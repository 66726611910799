import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd  } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';

@Component({
  selector: 'app-related-resources',
  templateUrl: './related-resources.component.html',
  styleUrls: ['./related-resources.component.scss']
})
export class RelatedResourcesComponent implements OnInit {
  imgURL = `${environment.apiURL}/assets/`
  allData:any;
  relatedResource:any;
  ContentSec:any;
  pageInfo:any;
  blogData: any;

  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
  });
  
    let relatedResourceData = this.transferState.useScullyTransferState('relatedData', this.data.getPageData(this.router?.url?.split('?')[0]));
    relatedResourceData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections;
        for(let i=0; i<=this.ContentSec?.length ; i++){
          if(this.ContentSec?.[i]?.content_section_id?.heading === "Related Resources" ){
            this.relatedResource = Array.of(this.ContentSec?.[i]?.content_section_id);
          }
        }
        this.blogData = this.allData?.blogs; 
        //console.log(this.blogData)   
        // for(let i=0; i<this.relatedResource?.[0]?.subcontent_sections.length; i++){
        //   this.relatedResource[0].subcontent_sections[i].keywordsArray= this.relatedResource?.[0]?.subcontent_sections?.[i]?.keywords?.split(', ')
        //   console.log(this.relatedResource[0].subcontent_sections[i].keywordsArray)
        // }
        for(let i=0; i<this.blogData?.length; i++){
          this.blogData[i].keywordsArray= this.blogData?.[i]?.blog_id?.keyword?.split(', ');
        }

      }        
    })
  }

  scrollTop(){
    window.scroll(0,0);
  }
}
