import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';

@Component({
  selector: 'app-navigation-sec',
  templateUrl: './navigation-sec.component.html',
  styleUrls: ['./navigation-sec.component.scss']
})
export class NavigationSecComponent implements OnInit {

  imgURL = `${environment.apiURL}/assets/`
  allData:any;
  buttonNavigation:any;
  ContentSec:any;
  pageInfo:any;

  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  ngOnInit(): void {
    let buttonNavigationData = this.transferState.useScullyTransferState('relatedData', this.data.getPageData(this.router?.url?.split('?')[0]));
    buttonNavigationData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections;
        for(let i=0; i<=this.ContentSec.length ; i++){
          if(this.ContentSec?.[i]?.content_section_id?.name === "Continue browsing" ){
            this.buttonNavigation = Array.of(this.ContentSec?.[i]?.content_section_id);
          }
        }
      }        
    })
  }

}
