<section *ngIf="blogData?.length > 0">
    <div class="blogs">
        <div class="container">
            <div class="blogs-section" *ngFor="let item of relatedResource">
                <div class="blogs-heading">
                    <h2 *ngIf="item.heading">{{item.heading}}</h2>
                    <a href="{{item?.slug}}" *ngIf="item.extra_link_text">{{item.extra_link_text}} <img loading="lazy" onerror="this.style.display='none'" alt="right-arrow" src="assets/img/homepage/right-arrow.png"
                        class="blog-arrow">
                    </a>
                </div>
                <!-- web view -->
                <div class="row web-view cardsFlow">
                    <div class="col-lg-4" *ngFor="let sub_item of blogData;let i=index" >
                        <div class="blog-card" routerLink="/blog/{{sub_item?.blog_id?.slug}}" (click)="scrollTop">
                            <div class="card-top top2" *ngIf="sub_item?.blog_id?.card_image?.id" [ngStyle]="{'background-image': 'url(' + imgURL+sub_item?.blog_id?.card_image?.id + ')'}" (click)="scrollTop">
                            </div>
                            <div class="card-body">
                                <h3 class="card-title" *ngIf="sub_item?.blog_id?.name">{{sub_item?.blog_id?.name}}</h3>
                                <p class="card-text" *ngIf="sub_item?.blog_id?.sub_head_description">{{sub_item?.blog_id?.sub_head_description}}</p>
                                <span class="badge badge-primary" *ngFor="let tag of sub_item?.keywordsArray">{{tag}}</span>
                                <div class="d-flex mt-1">
                                    <p class="detail">
                                        <ng-container *ngIf='sub_item?.blog_id?.author_name' >{{sub_item?.blog_id?.author_name}}</ng-container>  
                                        <ng-container *ngIf='sub_item?.blog_id?.date'> | {{sub_item?.blog_id?.date}}</ng-container>
                                    </p>
                                </div>
                                <a routerLink="/blog/{{sub_item?.blog_id?.slug}}" (click)="scrollTop" class="readMore">View Post<img onerror="this.style.display='none'" alt="right-arrow" src="../../../assets/img/right-arrow.svg"></a>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
            <div class="blogs-section mobile-view" *ngFor="let item of relatedResource">
                <!-- mobile view -->
                <div class="blog-mobile">
                    <ng-container *ngFor="let sub_item of blogData;let i=index" >
                        <div class="blog-card" routerLink="/blog/{{sub_item?.blog_id?.slug}}" (click)="scrollTop">
                            <div class="card-top top2" *ngIf="sub_item?.blog_id?.card_image?.id" [ngStyle]="{'background-image': 'url(' + imgURL+sub_item?.blog_id?.card_image?.id + ')'}">
                            </div>
                            <div class="card-body">
                                <h3 class="card-title" *ngIf="sub_item?.blog_id?.name">{{sub_item?.blog_id?.name}}</h3>
                                <p class="card-text" *ngIf="sub_item?.blog_id?.sub_head_description">{{sub_item?.blog_id?.sub_head_description}}</p>
                                <span class="badge badge-primary" *ngFor="let tag of sub_item?.keywordsArray">{{tag}}</span>
                                <div class="d-flex mt-1">
                                    <p class="detail">
                                        <ng-container *ngIf='sub_item?.blog_id?.author_name' >{{sub_item?.blog_id?.author_name}}</ng-container>  
                                        <ng-container *ngIf='sub_item?.blog_id?.date'> | {{sub_item?.blog_id?.date}}</ng-container>
                                    </p>
                                </div>
                                <a routerLink="/blog/{{sub_item?.blog_id?.slug}}" (click)="scrollTop" class="readMore">View Post<img onerror="this.style.display='none'" alt="right-arrow" src="../../../assets/img/right-arrow.svg"></a>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        
    </div>
</section>
