import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ServicesComponent } from './pages/services/services.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { CareersComponent } from './pages/careers/careers.component';
import { SolutionComponent } from './pages/solution/solution.component';
import { ToolsComponent } from './pages/tools/tools.component';
import { HttpClientModule } from '@angular/common/http';
import { ToolsInternalComponent } from './pages/tools-internal/tools-internal.component';
import { SolutionInternalComponent } from './pages/solution-internal/solution-internal.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CaseStudiesComponent } from './pages/case-studies/case-studies.component';
import { StoriesComponent } from './pages/stories/stories.component';
import { ApproachComponent } from './pages/approach/approach.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderTwoComponent } from './layout/header-two/header-two.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {NgxPaginationModule} from 'ngx-pagination';
import { CommonModule, DOCUMENT } from '@angular/common';
import { TestimonialComponent } from './common/testimonial/testimonial.component';
import { RelatedResourcesComponent } from './common/related-resources/related-resources.component';
import { RelatedCaseStudyComponent } from './common/related-case-study/related-case-study.component';
import { ContactAltComponent } from './common/contact-alt/contact-alt.component';
import { WhyBrooklynComponent } from './common/why-brooklyn/why-brooklyn.component';
import { NavigationSecComponent } from './common/navigation-sec/navigation-sec.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AboutComponent } from './pages/about/about.component';
import { AuditComponent } from './pages/audit/audit.component';
import { GeneralPageComponent } from './pages/general-page/general-page.component';
import { GtmComponent } from './layout/gtm/gtm.component';
import { ClientComponent } from './pages/client/client.component';
import { CardsSliderComponent } from './common/cards-slider/cards-slider.component';

@NgModule({
  declarations: [
    AppComponent,
    GtmComponent,
    HeaderComponent,
    HeaderTwoComponent,
    HomeComponent,
    FooterComponent,
    ServicesComponent,
    BlogsComponent,
    CareersComponent,
    SolutionComponent,
    ToolsComponent,
    ToolsInternalComponent,
    SolutionInternalComponent,
    ContactComponent,
    CaseStudiesComponent,
    StoriesComponent,
    ApproachComponent,
    TestimonialComponent,
    RelatedResourcesComponent,
    RelatedCaseStudyComponent,
    ContactAltComponent,
    WhyBrooklynComponent,
    NavigationSecComponent,
    ThankYouComponent,
    PageNotFoundComponent,
    AboutComponent,
    AuditComponent,
    GeneralPageComponent,
    ClientComponent,
    CardsSliderComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ScullyLibModule,
    BrowserAnimationsModule,
    CarouselModule,  
    NgxPaginationModule,
    CommonModule,
    ScrollToModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
