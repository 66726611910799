<app-header></app-header>



<!-- Hero-Section-solution starts -->

<div class="hero-section-tools">
    <div class="container" *ngFor="let item of banner">
        <h1 *ngIf="item?.heading">{{item?.heading}}</h1>
        <p *ngIf="item?.description">{{item?.description}}</p>
    </div>
</div>

<ng-container *ngFor="let item of FeaturedTools">
    <div class="tools-sec-content">
        <h2 *ngIf="item?.name">{{item?.name}}</h2>
        <p *ngIf="item?.description">{{item?.description}}</p>
    </div>

    <!-- tools partner cards starts -->

    <div class="partners">
        <div class="custom--container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12 mt-15" *ngFor="let subitem of item?.subcontent_sections">
                    <div class="justify-center">
                        <div class="card ">
                            <img loading="lazy" *ngIf="subitem?.subcontent_logo" src="{{imgURL}}{{subitem?.subcontent_logo?.id}}" alt="{{subitem?.subcontent_logo_alt_text}}">
                            <h4></h4>
                            <p *ngIf="subitem?.sub_head_description">{{subitem?.sub_head_description}}</p>
                            <div class="learn__more" aria-label="blog">
                                <a class="link" aria-label="blog-img" href="{{subitem?.extra_link}}" *ngIf="subitem?.extra_link_text">{{subitem?.extra_link_text}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- tools partner cards ends  -->

<!-- Data Stack Section Start -->
<ng-container *ngFor="let item of dataStack">
    <div class="container data-stack-sec-content">
        <h2 *ngIf="item?.heading">{{item?.heading}}</h2>
        <p *ngIf="item?.description">{{item?.description}}</p>
    </div>

    <!-- Data Stack Section End -->

    <!-- Catogory Section For Mobile View Starts -->


    <div class="category-selection">
        <ng-container *ngFor="let subitems of item?.subcontent_sections; let i=index">
            <div class="dropdown" *ngIf="i===0">
                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" *ngIf="subitems?.sub_head">
                    {{subitems?.sub_head}}
                    <img onerror="this.style.display='none'" src="/assets/img/arrow-down.svg" alt="arrow-down" class="arrow-down">
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="javascript:;" *ngFor="let sub of subitems?.sub_subcontent_sec_part; let j=index"   (click)="scrollCategory('#'+(j+1))" >{{sub?.name}}</a>
                </div>
            </div>
        </ng-container>
    </div>


    <!--  Catogory Section For Mobile View Ends -->


    <div class="container">
        <div class="row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-2">
                <div  class="side-scroll">

                    <ng-container *ngFor="let subitems of item?.subcontent_sections; let i=index;">
                        <div class="category-section-desktop" *ngIf="i===0">
                            <div class="nav-root">
                                <h6 *ngIf="subitems?.sub_head">{{subitems?.sub_head}}</h6>
                                <div>
                                    <a class="categroy-web-link"  (click)="scrollCategory('#'+(j+1))" tabindex="1" *ngFor="let sub of subitems?.sub_subcontent_sec_part ; let j=index">
                                        {{sub?.name}} 
                                        <img class="arrow__img" alt="image" onerror="this.style.display='none'" src="../../../assets/img/right-arrow.svg">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-10 col-12">
                <ng-container *ngFor="let subitems of item?.subcontent_sections; let i=index">
                <div class="nav-root cardRight" *ngIf="i!==0" id="{{i}}">
                    <!-- removed class="{{subitems.class}}" from h4 tag -->
                    <h4 *ngIf="subitems?.sub_head">{{subitems?.sub_head}}</h4> 
                    <div class="row right--section">
                        
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6" *ngFor="let sub_subitem of subitems?.sub_subcontent_sec_part ; let j=index">  
                            <div class="toolsCards">   
                                <ng-container>                    
                                    <img loading="lazy" *ngIf="sub_subitem?.image" class="img" src="{{imgURL}}{{sub_subitem?.image?.id}}" alt="{{subitems?.subhead_images_alt_text}}" />
                                    <a href="{{sub_subitem?.slug}}" class="viewMore" *ngIf="sub_subitem?.description">{{sub_subitem?.description}} <img alt="arrow" class="arrow" onerror="this.style.display='none'" src="../../../assets/img/right-arrow.svg"></a>
                                </ng-container>   
                            </div>
                        </div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
    </div>

</ng-container>


<!-- Our Expertise Section Starts -->
<ng-container *ngFor="let item of cBrowsing">
    <div class="container">
    <div class="our-expertise-sec-content">
        <h4 *ngIf="item?.heading">{{item?.heading}}</h4>
    </div>   
    <!-- Previous Button Starts --> 
        <ng-container *ngFor="let sub_item of item?.subcontent_sections; let i=index">
            <div class="prev">
                <a href="{{sub_item?.extra_link}}" aria-label="navigation" style="text-decoration: none;">
                    <div class="prev__card">
                        <p class="prev__subtitle" *ngIf="sub_item?.sub_head">{{sub_item?.sub_head}}</p>
                        <div class="d-flex">
                            <div>
                                <img loading="lazy" onerror="this.style.display='none'" class="prev__img" src="assets/img/left-arrow.png" alt="prev">
                            </div>
                            <div class="mx-auto">
                                <a class="prev__link"  *ngIf="sub_item?.extra_link_text">{{sub_item?.extra_link_text}}</a>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </ng-container>
    </div>
</ng-container>
<!-- Previous Button Ends -->

<!-- Contact Us Section starts here -->

<div class="pb-0">
    <div class="contact-sec" *ngFor="let item of contact">
        <div class="contact-sec2">
            <div class="custom--container">
                <div class="content">
                    <h6 *ngIf="item?.name">{{item?.name}}</h6>
                    <h3 *ngIf="item?.heading">{{item?.heading}}</h3>
                    <button class="btn btns mt-lg-4" (click)="goToPage('contact')" *ngIf="item?.button_label">{{item?.button_label}}</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Contact Us Section ends here  -->