<app-header-two></app-header-two>

<div class="hero-tools-internal">
    <div class="custom--container">
        <div class="row">
            <div class="col-md-4 col-12 justify-center">
                <ng-container *ngFor="let item of banner" >
                    <div class="card" >
                        <img loading="lazy" *ngIf="item.image" src="{{imgURL}}{{item.image?.id}}" alt="{{item?.image_alt_text}}">
                    </div>
                    <div class="learn__more">
                        <a class="view__resource__link link" *ngIf="item.extra_link_text" href="#viewResources">{{item?.extra_link_text}}
                            
                        </a>
                    </div>
                </ng-container>
            </div>
            <div class="col-md-8 col-12">
                <div class="content" *ngFor="let item of banner">
                    <h3 *ngIf="item.heading">{{item?.heading}}</h3>
                    <p *ngIf="item.description">{{item?.description}}</p>
                    <h3 *ngIf="item.additional_line">{{item?.additional_line}}</h3>
                    <div class="row">
                        <div class="col-md-5">
                        
                            <div class="list "  >
                                <div *ngFor="let subitem of item?.subcontent_sections?.[0]?.sub_subcontent_sec_part" class="grid grid-list-col d-flex mb-16 pr-15"><div><span class="list__icon"></span></div><div *ngIf="subitem.name"><span class="list__item">{{subitem?.name}}</span></div></div>
                            </div>
                        </div>
                        <div class="col-md-5">
                
                            <div class="list ml-20"  >
                                <div  *ngFor="let subitem of item?.subcontent_sections?.[1]?.sub_subcontent_sec_part" class="grid grid-list-col d-flex mb-16 pr-35"><div><span class="list__icon"></span></div><div *ngIf="subitem.name"><span class="list__item">{{subitem?.name}}</span></div></div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center-mob">
                        <img loading="lazy" *ngFor="let subitem of item?.subcontent_sections?.[2]?.subhead_images" class="looker--img1" src="{{imgURL}}{{subitem?.directus_files_id?.id}}" alt="{{item?.subcontent_sections[2]?.subhead_images_alt_text}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="partnership">
    <div class="custom--container">
        <div class="row">
            <div class="col-md-7 ">
                <div class="content" *ngFor="let item of partner">
                    <h2 *ngIf="item.heading">{{item?.heading}}</h2>
                    <p *ngIf="item.description">{{item?.description}}</p>
                </div>   
            </div>

                <div class="col-md-5 mt-75 pl-55">
                    <div class="list" *ngFor="let item of partner" >
                        <div  *ngFor="let subitem of item?.subcontent_sections" class="grid grid-list-col d-flex mb-16 pr-50"><div><span class="list__icon"></span></div><div *ngIf="subitem.sub_head"><span class="list__item">{{subitem.sub_head}}</span></div></div>
                    </div>
                </div>
        </div>
    </div>
</div>



<app-testimonial></app-testimonial>



<div style="margin-top: 60px;"></div>

 

 <app-related-resources></app-related-resources>

 <div style="margin-top: 10px;"></div>

 <div id="viewResources">
 <app-related-case-study></app-related-case-study>
 </div>

<!-- Blogs cards ends here  -->


<div class="mt-130"></div>

<app-contact-alt></app-contact-alt>
<!-- Contact Us ends here -->
