import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';

@Component({
  selector: 'app-related-case-study',
  templateUrl: './related-case-study.component.html',
  styleUrls: ['./related-case-study.component.scss']
})
export class RelatedCaseStudyComponent implements OnInit {
  imgURL = `${environment.apiURL}/assets/`
  allData:any;
  relatedCase:any;
  ContentSec:any;
  pageInfo:any;
  url:any;
  storyData: any;


  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  recieve(type:string){
    let relatedCaseData = this.transferState.useScullyTransferState('relatedData', this.data.getCase(this.router?.url?.split('?')[0], type));
    relatedCaseData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections
        for(let i=0; i<=this.ContentSec.length ; i++){
          if(this.ContentSec?.[i]?.content_section_id?.heading === "Related Case Studies" ){
            this.relatedCase = Array.of(this.ContentSec?.[i]?.content_section_id);
          }
        }
        this.storyData = this.allData?.stories; 
        for(let i=0; i<this.storyData?.length; i++){
          this.storyData[i].keywordsArray= this.storyData?.[i]?.client_story_id?.keyword?.split(', ');
        }
      }        
    })
  }

  recievePage(){
    let relatedCaseData = this.transferState.useScullyTransferState('relatedData', this.data.getPageData(this.router?.url?.split('?')[0]));
    relatedCaseData.subscribe((result)=>{
      this.allData = result;
      this.allData = this.allData?.data?.[0];
      // console.log(this.allData)
      // console.log(this.allData?.slug +" "+ this.router.url)
      if(this.allData?.slug == this.router?.url?.split('?')[0]){
        this.ContentSec = this.allData?.content_sections;
        for(let i=0; i<=this.ContentSec.length ; i++){
          if(this.ContentSec?.[i]?.content_section_id?.heading === "Related Case Studies" ){
            this.relatedCase = Array.of(this.ContentSec?.[i]?.content_section_id);
          }
        }
        this.storyData = this.allData?.stories; 
        for(let i=0; i<this.storyData?.length; i++){
          this.storyData[i].keywordsArray= this.storyData?.[i]?.client_story_id?.keyword?.split(', ');
        }
      }        
    })
  }

  ngOnInit(): void {
    this.url = this.router?.url?.split('?')[0];
    //console.log(this.url)
    if(this.url.indexOf("stories") >=0 ){
      this.recieve('client_story');
    }else if(this.url.indexOf("blog") >=0){
      this.recieve('blog');
    }else{
      this.recievePage()
    }
  }
  scrollTop(){
    window.scroll(0,0);
  }

}
