import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TransferStateService } from '@scullyio/ng-lib';
import { DataService } from 'src/app/data.service';
declare function loadMeta(titVal:any,metaTitVal:any,metaDesVal:any ):any;
declare function pageMetaTypeURL(url: any, type: any): any;

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {
  allData:any;
  page:any;
  titleValue:any='';
  metaTitVal:any='';
  metaDesVal:any='';
  pageInfo: any;
  pageUrl:any='';
  pageType:any='';

  constructor(private data:DataService, private router: Router, private transferState: TransferStateService) { }

  ngOnInit(): void {
    let thanksPage = this.transferState.useScullyTransferState('thanksPageTS', this.data.getPageData(this.router?.url?.split('?')[0]));
    thanksPage.subscribe((result)=>{
    this.allData = result;
    this.allData = this.allData?.data?.[0];
    console.log(this.allData);
    console.log(this.allData?.slug +" "+ this.router?.url?.split('?')[0])
    if(this.allData?.slug == this.router?.url?.split('?')[0]){
      this.page = this.allData?.content_sections;
      console.log(this.page);
      this.pageInfo = this.allData;
      this.titleValue = this.pageInfo?.title;
      this.metaTitVal = this.pageInfo?.meta_title_content;
      this.metaDesVal = this.pageInfo?.meta_description_content;
      loadMeta(this.titleValue, this.metaTitVal, this.metaDesVal);
    
      this.pageUrl = window?.location?.href;
        this.pageType = this.pageInfo?.metaOgType;
        pageMetaTypeURL(this.pageUrl, this.pageType);

    }
    });
  }

  ngOnDestroy(){
    let isMetaUrlAvailable = document.getElementById("metaURL");
    let isMetaTypeAvailable = document.getElementById("metaTYPE");
    isMetaUrlAvailable?.remove();
    isMetaTypeAvailable?.remove();
  }

}
